const availableLocationData = [
    {
        location: "Delhi",
        url: '/delhi',
        services: ['Training', 'Grooming', 'Walking', 'Boarding']
    },
    {
        location: "Mumbai",
        url: '/mumbai',
        services: ['Training', 'Grooming', 'Walking', 'Boarding']
    },
    {
        location: "Ghaziabad",
        url: '/ghaziabad',
        services: ['Training', 'Grooming', 'Walking', 'Boarding']
    },
    {
        location: "Greater Noida",
        url: '/greater-noida',
        services: ['Training', 'Grooming', 'Walking', 'Boarding']
    },
    {
        location: "Gurgaon",
        url: '/gurgaon',
        services: ['Training', 'Grooming', 'Walking', 'Boarding']
    },
    {
        location: "Faridabad",
        url: '/faridabad',
        services: ['Training', 'Grooming', 'Walking', 'Boarding']
    },
    {
        location: "Noida",
        url: '/noida',
        services: ['Training', 'Grooming', 'Walking', 'Boarding']
    },
]

module.exports = { availableLocationData }