import { useState, useEffect, useRef } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import iconsDesktop from "../../../images/iconsDesktop.png";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
import DeleteIcon from "../../../images/deleteIcon.png";
import CorruptIcon from "../../../images/CorruptIcon.png"
import { useHistory } from 'react-router-dom';

const HomePageForm = (props) => {
  const navigate = useNavigate();

  const [walking, setWalking] = useState(true);
  const [training, setTraining] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState(true);
  const [petValidation, setPetValidation] = useState(true);
  const [loading, setLoading] = useState(false);
  const location = useLocation().pathname;
  const date = new Date();
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [address1, setAddress] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [city, setCity] = useState("");
  const [loading1, setLoading1] = useState(true);
  // const [location, setLocation] = useState('/deleteacc');
  const [phoneOrEmail, setPhoneOrEmail] = useState('');
  // const [phoneValidation, setPhoneValidation] = useState(true); // Assuming it's initially valid
  // const [loading, setLoading] = useState(false);
  // const phoneRef = useRef(null);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);

  // declaration of refs
  const phoneRef = useRef(0);
  const dateRef = useRef("");
  const addressRef = useRef("");
  const petRef = useRef("");

  const [petName, setPetName] = useState("");

  // -------------------handler functions for form header switch-------------

  function walkingHandlerFunction() {
    setWalking(true);
  }

  function trainingHandlerFunction() {
    setWalking(false);
    setTraining(true);
  }
  
  const isEmail = (input) => {
   
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  async function submitWalkingTrainingDataHandler(path) {
    let link = "";
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;
    const dateOfReg = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;

    const stringPhone = phoneRef.current.value.toString();

    let UTM_Source,
      UTM_Medium,
      UTM_Campaign = "";

    const urlParams = new URLSearchParams(window.location.search);
    UTM_Source = urlParams.get("utm_source");
    UTM_Medium = urlParams.get("utm_medium");
    UTM_Campaign = urlParams.get("utm_campaign");
    console.log(dateRef.current.value);
    let data = {
      data: {
        ContactNo: phoneRef.current.value,
        DateOfRegistration: dateRef.current.value || dateOfReg,
        location: address1,
        TimeOfRegistration: timeOfReg,
        Address: addressRef.current.value,
        FormType: "Direct Form",
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
        TrialDate: "",
      },
    };

    if (addressRef !== "" && stringPhone.length === 10) {
      setLoading(true);
      if (path === "walking") {
        link = "https://api.apispreadsheets.com/data/QcQTxBvwdD8rfShs/";
      } else if (path === "training") {
        console.log("Submitting the training data!");
        link =
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveTrainingData";
      }

      await axios
        .post(link, data)
        .then((response) => {
          console.log("hey2", response);
        })
        .catch((err) => {
          console.log("hey3", err);
        });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `${path} Registration Fired From Desktop Service Page Form`,
      });

      phoneRef.current.value = "";
      setLoading(false);
      navigate("/thankPage");
    } else {
      setPhoneValidation(true);
      phoneRef.current.value = "";
    }
  }

  // if (isNaN(phoneOrEmail) && !isEmail(phoneOrEmail)) {
  //   setPhoneValidation(false);
  //   return;
  // }
  console.log('Submit handler:', phoneOrEmail);
    // setLoading(true); 
    // setLoading(false);

  return (
    <div className="home-page-desktop-intro-form-container2">
      {/* Part 1 */}

      {location === "/deleteacc" && !isDeleteConfirmed && (
        <>
          <div className="walking-page-desktop-intro-form-header">
            <div>Enter your Details</div>
          </div>
          <div className="walking-page-desktop-intro-form-contents-container">
            <div className="walking-page-desktop-intro-form-contents-upper">
              {location === "/deleteacc" && (
                <>
                  <div className="walking-page-desktop-intro-form-contents-upper-left">
                    <div className="home-page-desktop-input-container">
                      <div>Phone Number/Email</div>

                      {!phoneValidation && (
                        <div className="home-page-desktop-phone-validation-text">
                          Please enter a valid phone number or email
                        </div>
                      )}
                    </div>
                    <input
                      onChange={(e) => {
                        setPhoneValidation(true);
                        setPhoneOrEmail(e.target.value);
                      }}
                      ref={phoneRef}
                      className="walking-page-phone-input"
                      type="text" // Change type to text to accept both numbers and emails
                      placeholder="Enter phone number / email"
                      name="phone"
                      required
                    />
                  </div>
                  <div className="walking-page-desktop-intro-form-contents-upper-right">
                    <div className="home-page-intro-bottom-btn">
                      {loading ? (
                        <LoadingSpinner />
                      ) : (
                        <button
                          onClick={() => setIsDeleteConfirmed(true)}
                          className="booksession-form"
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>{" "}
        </>
      )}
      {/* part 1 ends */}

      {/* part 2 starts */}
      {location === "/deleteacc" && isDeleteConfirmed && (
        <>
          <div className="walking-page-desktop-intro-form-header">
            <div>Enter your Details</div>
          </div>
          <div className="walking-page-desktop-intro-form-contents-container">
            <div
              className="walking-page-desktop-intro-form-contents-upper"
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
                justifyContent:"center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={DeleteIcon}
                  style={{ width: "5rem", height: "5rem", margin: "0 1rem" }}
                />
                <p
                  style={{
                    color: "#ef7348",
                    fontFamily: "Lato",
                    fontSize: "1rem",
                    fontStyle: "normal",
                    fontWeight: "700",
                  }}
                >
                  Your account will be deleted in 7 business days
                </p>
              </div>
            </div>

            <div className="walking-page-desktop-intro-form-contents-upper-right">
              <div className="home-page-intro-bottom-btn">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <button
                    onClick={() => submitWalkingTrainingDataHandler("training")}
                    className="booksession-form"
                  >
                    Okay
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {/* part 2 ends */}

      {/* part 3 */}

      {/* <div className="walking-page-desktop-intro-form-header">
        <div>Search Client Details</div>
      </div>
      <div className="walking-page-desktop-intro-form-contents-container">
        <div
          className="walking-page-desktop-intro-form-contents-upper"
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              //   padding: "0 10rem",
            }}
          >
            <img
              src={CorruptIcon}
              style={{ width: "5rem", height: "5rem", margin: "0 1rem" }}
            />
            <p
              style={{
                color: "#ef7348",
                fontFamily: "Lato",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: "700",
                paddingRight: "8rem",
              }}
            >
              Client Data not available!
            </p>
            <div className="walking-page-desktop-intro-form-contents-upper-right">
              <div className="home-page-intro-bottom-btn">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <button
                    onClick={() => submitWalkingTrainingDataHandler("training")}
                    className="booksession-form"
                  >
                    Search Other
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default HomePageForm;
