import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../components/LandingPage.css";

import { Helmet } from "react-helmet-async";
import LazyLoad from "react-lazyload";
import FAQContainer from "../../components/container/faqContainer/FAQContainer";
import HeartContainer from "../../components/container/heartContainer/HeartContainer";
import IntroContainer from "../../components/container/introContainer/IntroContainer";
import IntroContainerDesktop from "../../components/container/introContainer/IntroContainerDesktop";
import Footer from "../../components/footer/Footer";
import ServiceBenefits from "../../components/serviceBenefits/ServiceBenefits";
import TimelineComponent from "../../components/TimelineComponent";
import { timelineData } from "../../mockData/mockData";
import classes from "./LandingPage.module.css";
// import Slider from "react-slick";
import { BsChevronRight } from "@react-icons/all-files/bs/BsChevronRight";
import { BsChevronLeft } from "@react-icons/all-files/bs/BsChevronLeft";
import "react-dots-loader/index.css";

const testimonialArray = [
  {
    message:
      "Got myself Advanced Training Package for both my dog, German & Golden. I never realised what all they could learn if trained properly 🙏 Thank you so much",
    name: "Rudrang",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-rudrang.webp",
    profession: "Tattoo Artist",
  },
  {
    message:
      "Petmojo has been a great partner in my new pet parenting journey. I worry less knowing my dog is getting trained and walks everyday.",
    name: "Ronak T",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-ronak.webp",
    profession: "Shop Owner",
  },
  {
    message:
      "Quality Service from Petmojo Team. Definitely will go for it again 10/10",
    name: "Deepak B",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-deepak.webp",
    profession: "Shop Owner",
  },
  {
    message:
      "The grooming process has been extremly easy and pocket-friendly! My pup looked amazing and didn’t seem distressed at all. I book their home service regurly.",
    name: "Vishal",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-vishal.webp",
    profession: "Software Engineer",
  },
  {
    message:
      "Third time the charm. After trying 2 training from my local area, I got a very good trainer on this app. Really liked the defined approach.",
    name: "Jay Bhosle",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-jay.webp",
    profession: "Proctor",
  },
];

function LandingPage({ changeView }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("Daily Dog Exercise/Walking");
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(10);
  const [value3, setValue3] = useState(10);
  const [valueMobile, setValueMobile] = useState(10);
  const [valueMobile2, setValueMobile2] = useState(10);
  const [valueMobile3, setValueMobile3] = useState(10);
  // const [changeView, setChangeView] = useState(false);
  const [scroll, setScroll] = useState(true);
  const [scroll1, setScroll1] = useState(true);
  const [scroll2, setScroll2] = useState(true);
  const [scroll3, setScroll3] = useState(true);
  const [scroll4, setScroll4] = useState(true);
  const [scroll5, setScroll5] = useState(true);
  const path = useLocation().pathname;
  // console.log(path);
  const handleChange = (value) => {
    setValue1(value);
    // setScroll(false);
    const scrollableDiv = document.getElementById("scrollable-div");
    scrollableDiv.scrollLeft =
      (value1 / 100) * (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };

  function rightScroll() {
    console.log("right scroll");
    const right = document.getElementById("scrollable-div");
    right.scrollBy(200, 0);
  }
  function leftScroll() {
    console.log("right scroll");
    const right = document.getElementById("scrollable-div");
    right.scrollBy(-200, 0);
  }
  function rightScroll2() {
    console.log("right scroll");
    const right = document.getElementById("scrollable-div2");
    right.scrollBy(200, 0);
  }
  function leftScroll2() {
    console.log("right scroll");
    const right = document.getElementById("scrollable-div2");
    right.scrollBy(-200, 0);
  }
  function rightScroll3() {
    console.log("right scroll");
    const right = document.getElementById("scrollable-div3");
    right.scrollBy(200, 0);
  }
  function leftScroll3() {
    console.log("right scroll");
    const right = document.getElementById("scrollable-div3");
    right.scrollBy(-200, 0);
  }
  const handleChangeMobile = (value) => {
    setValueMobile(value);
    setScroll1(false);

    const scrollableDiv = document.getElementById("scrollable-div-mobile");
    scrollableDiv.scrollLeft =
      (valueMobile / 100) *
      (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };

  const handleChange2 = (value) => {
    setValue2(value);
    // setScroll2(false);

    const scrollableDiv = document.getElementById("scrollable-div2");
    scrollableDiv.scrollLeft =
      (value2 / 100) * (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };
  const handleChangeMobile2 = (value) => {
    setValueMobile2(value);
    setScroll3(false);

    const scrollableDiv = document.getElementById("scrollable-div2-mobile");
    scrollableDiv.scrollLeft =
      (valueMobile2 / 100) *
      (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };
  const handleChange3 = (value) => {
    setValue3(value);
    // setScroll4(false);

    const scrollableDiv = document.getElementById("scrollable-div3");
    scrollableDiv.scrollLeft =
      (value3 / 100) * (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };
  const handleChangeMobile3 = (value) => {
    setValueMobile3(value);
    setScroll5(false);

    const scrollableDiv = document.getElementById("scrollable-div3-mobile");
    scrollableDiv.scrollLeft =
      (valueMobile3 / 100) *
      (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };

  const [testmonialData, setTestimonialData] = useState({
    message:
      "Got myself Advanced Training Package for both my dog, German & Golden. I never realised what all they could learn if trained properly 🙏 Thank you so much",
    name: "Rudrang",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-rudrang.webp",
    profession: "Tattoo Artist",
  });

  // useEffect(() => {
  //   if (window.innerWidth <= 1000) {
  //     setChangeView(false);
  //   } else {
  //     setChangeView(true);
  //   }
  // }, []);

  const scrollToTop = () => {
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  let count = 0;

  useEffect(() => {
    const targetDiv = document.querySelector("#testimonialContainer");
    console.log(targetDiv);

    const interval = setInterval(() => {
      count++;
      if (count === 5) {
        count = 0;
      }
      setTestimonialData(testimonialArray[count]);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    script1.type = "application/ld+json";
    script1.innerText = JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "Review",
      author: {
        "@type": "Person",
        name: "Paritosh Chakraborty",
      },
      itemReviewed: {
        "@type": "LocalBusiness",
        name: "Petmojo Pet Care Services",
        address: "New Delhi, India",
        image:
          "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-logo-white.png",
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: "4.7",
          ratingCount: "2814",
        },
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "4.8",
        reviewAspect: "",
      },
      publisher: {
        "@type": "Person",
        name: "Sushil Anand",
      },
      reviewBody:
        "Petmojo’s services are exceptional. The way they care about dogs is to be admired. I can say that they can take care of my dog better than I can myself!",
    });

    script2.type = "application/ld+json";
    script2.innerText = JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "Why should we choose Petmojo?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Our expert trainers have up to 20+ years of training experience. Moreover, we are super empathetic towards your furry friend using only positive reinforcement to re-model behavior. Our mission is to improve your lifelong relationship with your pet by making it more harmonious. We care deeply about overall animal welfare.",
          },
        },
        {
          "@type": "Question",
          name: "Who will be my pet’s Trainer?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "PetMojo’s in-house trainers are experienced, reliable, and verified through a comprehensive background check. Our trainers are focused on delivering results with positive and reward-based training.",
          },
        },
        {
          "@type": "Question",
          name: "In what areas do you provide services?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "We provide services in Delhi, Gurgaon, Noida, Greater Noida, Ghaziabad, Chandigarh, Mumbai, Bangalore, Pune, Kolkata, Chennai, and Bhopal at the moment.",
          },
        },
        {
          "@type": "Question",
          name: "What Breeds of Dogs do you Train?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "We have trained all breeds of dogs. You would be hard-pressed to find a breed that we have not trained – from Chihuahua to Great Dane, big or small, we train them all!",
          },
        },
        // {
        //   "@type": "Question",
        //   name: "What is the Cancellation and Refund Policy?",
        //   acceptedAnswer: {
        //     "@type": "Answer",
        //     text: "We will refund 100% of the remaining sessions – no questions asked. To claim the refund please contact our customer support team.",
        //   },
        // },
      ],
    });
    script1.id = "homepage1";
    script2.id = "homepage2";
    document.body.appendChild(script1);
    document.body.appendChild(script2);

    return () => {
      document.getElementById("homepage1").remove();
      document.getElementById("homepage2").remove();
    };
  }, []);

  useEffect(() => {
    document.head.innerHTML += `
    <meta property="og:title" content="Petmojo | Premium Pet Care Services at Home" id="homepage-1" />
    <meta property="og:site_name" content="Petmojo" id="homepage-2" />
    <meta property="og:url" content="https://petmojo.care" id="homepage-3" />
    <meta property="og:description" content="Discover Petmojo for top-notch pet care services right at your doorstep. Petmojo's expert team offers dog walking, cat and dog training, and grooming to keep your pets happy and healthy." id="homepage-4" />
    <meta property="og:type" content="website" id="homepage-5" />
    <meta property="og:image" content="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-logo-white.png" id="homepage-6"
    />
    
    <title id="homepage-id">Petmojo | Premium Pet Care Services at Home</title>
    <meta id="homepage-desc" name="description" content="Discover Petmojo for top-notch pet care services right at your doorstep. Petmojo's expert team offers dog walking, cat and dog training, and grooming to keep your pets happy and healthy." />
    <meta id="homepage-keywords" name="keywords" content="Dog Vaccination in Delhi, Best Pet Care Services, Petmojo Application" />
    `;

    return () => {
      document.getElementById("homepage-1").remove();
      document.getElementById("homepage-2").remove();
      document.getElementById("homepage-3").remove();
      document.getElementById("homepage-4").remove();
      document.getElementById("homepage-5").remove();
      document.getElementById("homepage-6").remove();
      document.getElementById("homepage-id").remove();
      document.getElementById("homepage-desc").remove();
      document.getElementById("homepage-keywords").remove();
    };
  }, []);

  return (
    <div className="landingpage">
      <Helmet>
        <title>
          Book an Online pet care services at home | Petmojo
        </title>
        <meta
          name="description"
          content="Book reliable pet care services at home with Petmojo! Find expert in-home pet care, pet care centres near you, and 'pet care near me' options for your furry friends."
        />
      </Helmet>

      {!changeView ? <IntroContainer /> : <IntroContainerDesktop />}

      {!changeView ? (
        <div style={{ width: "100%", marginTop: "20px" }}>
          <img
            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/homepage-banner-mobile.webp"
            alt="banner-mobile"
            style={{ width: "100%" }}
            loading="lazy"
          />
        </div>
      ) : (
        <div
          style={{ width: "100%", marginBottom: "40px", position: "relative" }}
          id="banner-homepage"
        >
          <img
            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-banner.webp"
            alt="banner-desktop"
            style={{ width: "100%" }}
            loading="lazy"
          />
        </div>
      )}
      {!changeView ? (
        <div
          className="landing-page-mobile-view-heart-container"
          style={{ marginTop: "20px" }}
        >
          <HeartContainer pathname={path} />
        </div>
      ) : (
        <div className={classes.heartSectionHomePage}>
          <div
            className={classes.heartSectionTextContainer}
            style={{ padding: "0px" }}
          >
            <div
              className={classes.heartSectionTextHeading2}
              style={{ marginBottom: "0px" }}
            >
              <div className="heart-section-head-1">
                <h3 className={classes.heartSectionTextHeading}>
                  Your Pet, Our Family 💖
                </h3>
                <div className="line-div relative-line">
                  <hr
                    className="live-training-pink-line"
                    style={{ position: "relative", bottom: "5px" }}
                  ></hr>
                </div>
              </div>
            </div>

            <div
              style={{
                padding: "0px",
                margin: "0px",
                position: "relative",
                bottom: "30px",
              }}
            >
              <h4 className={classes.heartSectionText}>
                <div>
                  <span className="pink">16000+</span> monthly happy pet
                </div>
                <div>parents on our platform!</div>
              </h4>
            </div>
          </div>
          <div className={classes.heartSectionImage}>
            <img
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-heart-container.webp"
              alt="heart"
              className="hearticon"
            ></img>
          </div>
        </div>
        // <div className="landingpage-homescreen" style={{display:"flex",padding:"60px 120px",justifyContent:"space-between"}}>
        //   <div  style={{padding:"0px" }}>
        //     <div  style={{marginBottom:"0px"}}>
        //       <div className="heart-section-head-1" >
        //         <h3 className="" style={{fontSize:"50px",fontWeight:"bold"}}>Your Pet, Our Family 💖</h3>
        //         <div className="line-div relative-line">
        //           <hr className="live-training-pink-line"></hr>
        //         </div>
        //       </div>
        //       <div  style={{padding:"0px",margin:"0px"}}>
        //       <h4 className="" style={{fontSize:"20px"}}>
        //         <div><span className="pink">16000+</span> monthly happy pet</div>
        //         <div>parents on our platform!</div>
        //       </h4>
        //     </div>
        //     </div>

        //   </div>
        //   <div >
        //   <img
        //       src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-heart-container.webp"
        //       alt="heart"
        //       className="hearticon"
        //     ></img>
        //   </div>
        // </div>
      )}

      <div
        className={
          !changeView
            ? "sliderServiceContainer pink-container-family"
            : "slider-service-container-desktop"
        }
      >
        {!changeView ? (
          <div>
            <div className="textbox2">Our Dog Training Services</div>
            <hr className="vector2"></hr>
          </div>
        ) : null}

        <div className="slider-container" style={{}}>
          {!changeView ? null : (
            <div className={classes.landingGroomingContainer}>
              <div className={classes.landingWalkingHeading}>
                <div className={classes.leftHead}>
                  <Link to="/dog-training" style={{ textDecoration: "none" }}>
                    <h2 className={classes.leftHead}>
                      Our Dog Training Services
                    </h2>
                  </Link>
                </div>
                <div className="line-div relative-line">
                  <hr
                    className="live-training-pink-line"
                    style={{ position: "relative", top: "10px" }}
                  ></hr>
                </div>
              </div>

              <div
                className="home-page-walking-packages-desktop-container2"
                style={{
                  position: "relative",
                  bottom: "10px",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                <h4 >
                  <div style={{ marginTop: "2rem" }}>Check out our Latest Training</div>
                  <div>Packages Including</div>
                  <div className="bold-grey">
                    Canine Misbehaviour Correction.
                  </div>
                </h4>
              </div>

              <div
                className={classes.gettingStartedBookingBtn1}
                style={{ position: "relative", bottom: "15px" }}
              >
                <div className="bookingbutton">
                  {/* <Link to="/popup" state={{ type: "dog-training" }}> */}
                  <button className="booksession" style={{ width: "20rem", marginTop: "1rem" }} onClick={() => scrollToTop()}>
                    Book a FREE Session
                  </button>
                  {/* </Link> */}
                  <div className="limitedslots">
                    Hurry Up, Limited slots left!
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div className={ !changeView ? "slider home-packages" : "slider home-packages-desktop"} > */}

          {!changeView ? (
            <div
              className="slider home-packages"
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "0px",
              }}
              id="scrollable-div-mobile"
            >
              <div className={classes.horizontalScroll}>
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-package-1-header">
                      <div className="unfaded-title">PUPPY TRAINING</div>
                      <div className="home-package-subtitle">
                        12 Sessions | 1 Month
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>Potty Training, Instruction,</i>
                          </div>
                          <div>
                            <i>Feedback Intelligence...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/solid-pink-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/puppy-training-icon.png"
                            alt="package icon"
                            className="packageicon"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-package-2-header">
                      <div className="unfaded-title">BASIC TRAINING</div>
                      <div className="home-package-subtitle">
                        24 Sessions | 2 Months
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>Play Module, Greetings, Without</i>
                          </div>
                          <div>
                            <i>Leash Walking...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/faded-purple-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/basic-training-icon.png"
                            alt="package icon"
                            className="packageicon"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-package-3-header">
                      <div className="unfaded-title">INTERMEDIATE TRAINING</div>
                      <div className="home-package-subtitle">
                        36 Sessions | 3 Months
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>Lower Acrobat Module - Crawl</i>
                          </div>
                          <div>
                            <i>Rollover, Lie Down, Sleep...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/solid-green-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/intermediate-training-icon.png"
                            alt="package icon"
                            className="packageicon"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-package-4-header">
                      <div className="unfaded-title">SMART TRAINING</div>
                      <div className="home-package-subtitle">
                        48 Sessions | 4 Months
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>Smart Commands, Advanced Play,</i>
                          </div>
                          <div>
                            <i>Advanced Greetings...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-orange-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/advanced-training-icon.png"
                            alt="package icon"
                            className="packageicon"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-package-6-header">
                      <div className="unfaded-title">ADVANCED TRAINING</div>
                      <div className="home-package-subtitle">
                        72 Sessions | 6 Months{" "}
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>Protection & Guard Module,</i>
                          </div>
                          <div>
                            <i>Sniff & Search...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-blue-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/premium-trainin-icon.png"
                            alt="package icon"
                            className="packageicon"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-package-4-header">
                      <div className="unfaded-title">
                        CANINE BEHAVIOUR THERAPY
                      </div>
                      <div className="home-package-subtitle">
                        48 Sessions | 4 Months
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>Excessive Barking, Biting, Growling</i>
                          </div>
                          <div>
                            <i>Lunging, Fear Anxiety...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/orange-pink-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/advanced-training-icon.png"
                            alt="package icon"
                            className="packageicon"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-package-7-header" style={{ background: "linear-gradient(180deg, #8DDE71 0%, #004440 100%)" }}>
                      <div className="unfaded-title" >
                        CANINE BEHAVIOUR THERAPY
                      </div>
                      <div className="home-package-subtitle">
                        60 Days | 2 Months {" "} (Resort Stay & Training)
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>Excessive Barking, Biting, Growling</i>
                          </div>
                          <div>
                            <i>Lunging, Fear Anxiety...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower" >
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/canine-beahaviour-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/canine-behaviour-icon.png"
                            alt="package icon"
                            className="packageicon"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-package-7-header" style={{ background: "linear-gradient(180deg, #8DDE71 0%, #004440 100%)" }}>
                      <div className="unfaded-title" >
                        CANINE BEHAVIOUR THERAPY
                      </div>
                      <div className="home-package-subtitle">
                        90 Days | 3 Months {" "} (Resort Stay & Training)
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>Excessive Barking, Biting, Growling</i>
                          </div>
                          <div>
                            <i>Lunging, Fear Anxiety...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower" >
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/canine-beahaviour-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/canine-behaviour-icon.png"
                            alt="package icon"
                            className="packageicon"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
















              </div>
              {/* <div style={{}}>
                <SimpleLoader color='red'/>
                </div> */}
            </div>
          ) : (
            // <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
            <>
              <button
                style={{
                  height: "150px",
                  borderRadius: "5px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderColor: "rgba(254, 119, 152, 1)",
                  border: "2px solid rgba(254,119,152,1)",
                  cursor: "pointer",
                  background: "none",
                }}
                onClick={leftScroll}
              >
                <BsChevronLeft color="rgba(254,119,152,1)" size={20} />
              </button>
              <div
                className="slider-training-packages"
                style={{ scrollBehavior: "smooth", alignItems: "center" }}
                id="scrollable-div"
              >
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-package-1-header">
                      <div className="unfaded-desktop-title">PUPPY TRAINING</div>
                      <div className="home-package-desktop-subtitle">
                        12 Sessions | 1 Month
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-desktop-content-left-upper">
                          <div>
                            <i>Potty Training, Instruction,</i>
                          </div>
                          <div>
                            <i>Socialization chart...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/solid-pink-arrow.png"
                                className="mini-card-arrow-icon-desktop"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/puppy-training-icon.png"
                            alt="package icon"
                            className="packageicon-desktop1-1"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-package-2-header">
                      <div className="unfaded-desktop-title">BASIC TRAINING</div>
                      <div className="home-package-desktop-subtitle">
                        24 Sessions | 2 Months
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-desktop-content-left-upper">
                          <div>
                            <i>Basic Social Etiquettes, Leash </i>
                          </div>
                          <div>
                            <i>Walking, Potty/Pee ...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/faded-purple-arrow.png"
                                className="mini-card-arrow-icon-desktop"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/basic-training-icon.png"
                            alt="package icon"
                            className="packageicon-desktop1"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-package-3-header">
                      <div className="unfaded-desktop-title">
                        INTERMEDIATE TRAINING
                      </div>
                      <div className="home-package-desktop-subtitle">
                        36 Sessions | 3 Months
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-desktop-content-left-upper">
                          <div>
                            <i>Advanced Leash Walking, Off</i>
                          </div>
                          <div>
                            <i> leash walking, Ettiquttes...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/solid-green-arrow.png"
                                className="mini-card-arrow-icon-desktop"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/intermediate-training-icon.png"
                            alt="package icon"
                            className="packageicon-desktop1"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-desktop-package-5-header">
                      <div className="unfaded-desktop-title">SMART TRAINING</div>
                      <div className="home-package-desktop-subtitle">
                        48 Sessions | 4 Months
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-desktop-content-left-upper">
                          <div>
                            <i>Smart Commands, Advanced Play,</i>
                          </div>
                          <div>
                            <i>Advanced Greetings...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-orange-arrow.png"
                                className="mini-card-arrow-icon-desktop"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/advanced-training-icon.png"
                            alt="package icon"
                            className="packageicon-desktop1"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-desktop-package-6-header">
                      <div className="unfaded-desktop-title">
                        ADVANCED TRAINING
                      </div>
                      <div className="home-package-desktop-subtitle">
                        72 Sessions | 6 Months{" "}
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-desktop-content-left-upper">
                          <div>
                            <i>Protection & Guard Module, </i>
                          </div>
                          <div>
                            <i>Sniff & Search...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-blue-arrow.png"
                                className="mini-card-arrow-icon-desktop"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/premium-trainin-icon.png"
                            alt="package icon"
                            className="packageicon-desktop1"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-desktop-package-5-header">
                      <div className="unfaded-desktop-title">
                        CANINE BEHAVIOUR THERAPY
                      </div>
                      <div className="home-package-desktop-subtitle">
                        48 Sessions | 4 Months
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-desktop-content-left-upper">
                          <div>
                            <i>Excessive Barking, Biting, Growling</i>
                          </div>
                          <div>
                            <i>Lunging, Fear Anxiety...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-orange-arrow.png"
                                className="mini-card-arrow-icon-desktop"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/advanced-training-icon.png"
                            alt="package icon"
                            className="packageicon-desktop1-agression"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-package-7-header" style={{ background: "linear-gradient(180deg, #8DDE71 0%, #004440 100%)" }}>
                      <div className="unfaded-desktop-title">
                        CANINE BEHAVIOUR THERAPY
                      </div>
                      <div className="home-package-desktop-subtitle">
                        60 Days | 2 Months{" "} (Resort Stay & Training)
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-desktop-content-left-upper">
                          <div>
                            <i>Excessive Barking, Biting, Growling</i>
                          </div>
                          <div>
                            <i>Lunging, Fear Anxiety...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/canine-beahaviour-arrow.png"
                                className="mini-card-arrow-icon-desktop"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/canine-behaviour-icon.png"
                            alt="package icon"
                            className="packageicon-desktop1-agression"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-training" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-training-package-7-header" style={{ background: "linear-gradient(180deg, #8DDE71 0%, #004440 100%)" }}>
                      <div className="unfaded-desktop-title">
                        CANINE BEHAVIOUR THERAPY
                      </div>
                      <div className="home-package-desktop-subtitle">
                        90 Days | 3 Months {" "} (Resort Stay & Training)
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-desktop-content-left-upper">
                          <div>
                            <i>Excessive Barking, Biting, Growling</i>
                          </div>
                          <div>
                            <i>Lunging, Fear Anxiety...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-training" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/canine-beahaviour-arrow.png"
                                className="mini-card-arrow-icon-desktop"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/canine-behaviour-icon.png"
                            alt="package icon"
                            className="packageicon-desktop1-agression"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>













                {/* </div> */}

                {/* // </div> */}
              </div>
              <button
                style={{
                  height: "150px",
                  borderRadius: "5px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderColor: "rgba(254, 119, 152, 1)",
                  border: "2px solid rgba(254,119,152,1)",
                  cursor: "pointer",
                  background: "none",
                }}
                onClick={rightScroll}
              >
                <BsChevronRight color="rgba(254,119,152,1)" size={20} />
              </button>
            </>
          )}
          {/* </div> */}
        </div>
        {!changeView ? (
          <center style={{}}>{/* <Loader color="#ff5e95" /> */}</center>
        ) : null}
      </div>

      {!changeView ? (
        <div
          className="bookingbutton"
          style={{
            paddingLeft: "30px",
            paddingRight: "30px",
            display: "flex",
            justifyContent: "flex-start",
            position: "relative",
            bottom: "20px",
          }}
        >
          <button
            className="booksession-mobile-view"
            onClick={() => scrollToTop()}
            style={{ width: "100%" }}
          >
            Book a FREE Session
          </button>

          <div className="limitedslots">Hurry Up, Limited slots left!</div>
        </div>
      ) : null}

      {!changeView ? (
        <div
          className="training-page-mobile-view-why-container"
          style={{ marginTop: "0px" }}
        >
          <div className="services-container2">
            <div className="service-box">
              <div
                className="textbox2"
                style={{ color: "#5D5D5D", fontWeight: 700 }}
              >
                Why Choose Petmojo?
              </div>
              <hr className="vector1"></hr>
              <div className="service-1">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/trust-icon.png"
                    alt="trust icon"
                    className="trust-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Trusted & Caring Trainers
                </div>
              </div>
              <div className="service-2">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/door-icon.png"
                    alt="door icon"
                    className="door-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Service at Your Door-Step{" "}
                </div>
              </div>
              <div className="service-3">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/refund-icon.png"
                    alt="refund icon"
                    className="refund-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Guaranteed Satisfaction{" "}
                </div>
              </div>
              <div className="service-4">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/team-icon.png"
                    alt="team icon"
                    className="team-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Local Onground Support
                </div>
              </div>

              {/* <div className="grey-background">
              <div className="bookingbutton">
                <Link to="/popup" state={{ type: "dog-training" }}>
                  <button className="booksession-mobile-view2">Book a FREE Session</button>
                </Link>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="home-page-why-choose-container">
          <div className="home-page-why-choose-container4">
            <div
              className="home-page-why-choose-container1-heading-seo"
              style={{ marginBottom: "0px" }}
            >
              <div>
                <h3 className="non-bold">Why Choose Petmojo?</h3>
              </div>
              <div className="line-div relative-line">
                <hr
                  className="live-training-pink-line"
                  style={{ position: "relative", bottom: "10px", marginTop: "1rem" }}
                ></hr>
              </div>
            </div>

            <div
              className="home-page-why-choose-container1-content"
              style={{ marginTop: "0px", position: "relative", bottom: "30px" }}
            >
              <div>
                <h4 style={{ marginTop: "2rem" }}>We are worlds most empathetic Pet Care Services.</h4>
              </div>
            </div>
          </div>

          <div className="home-page-why-choose-container2">
            <div className="service-1">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/trust-icon.png"
                  alt="trust icon"
                  className="trust-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Trusted & Caring Trainers
              </div>
            </div>
            <div className="service-2">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/door-icon.png"
                  alt="door icon"
                  className="door-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Service at Your Door-Step{" "}
              </div>
            </div>
            <div className="service-3">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/refund-icon.png"
                  alt="refund icon"
                  className="refund-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Guaranteed Satisfaction{" "}
              </div>
            </div>
            <div className="service-4">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/team-icon.png"
                  alt="team icon"
                  className="team-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Local Onground Support
              </div>
            </div>
          </div>

          <div className="home-page-why-choose-container3">
            <img
              className="home-page-why-choose-container3-img"
              alt="heroImg"
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-bg-dog.webp"
            />
          </div>
        </div>
      )}

      <div
        className={
          !changeView
            ? "sliderServiceContainer pink-container-family"
            : "sliderServiceContainer-desktop"
        }
      >
        {!changeView ? (
          <div>
            <div className="textbox2">
              Our Daily Exercises & Walking Packages
            </div>
            <hr className="vector2"></hr>
          </div>
        ) : null}

        <div className="slider-container">
          {!changeView ? null : (
            <div className={classes.landingGroomingContainer}>
              <div className={classes.landingWalkingHeading}>
                <div className={classes.leftHead}>
                  <Link to="/dog-running" style={{ textDecoration: "none" }}>
                    <h2 className={classes.leftHead}>
                      Our Daily Exercise & Walking Packages
                    </h2>{" "}
                  </Link>
                </div>
                <div className="line-div relative-line">
                  <hr
                    className="live-training-pink-line"
                    style={{ position: "relative", top: "10px" }}
                  ></hr>
                </div>
              </div>

              <div
                className="home-page-walking-packages-desktop-container2"
                style={{ position: "relative", bottom: "15px" }}
              >
                <h4 style={{ marginTop: "2rem" }}>
                  <div>Check your pet's LIVE location on</div>
                  <div>our mobile application when</div>
                  <div>session is ongoing.</div>
                </h4>
              </div>

              <div
                className={classes.gettingStartedBookingBtn1}
                style={{ position: "relative", bottom: "15px" }}
              >
                <div className="bookingbutton">
                  <Link to="/dog-running" state={{ type: "dog-running" }}>
                    <button className="booksession">Book 1st FREE Walk</button>
                  </Link>
                  <div className="limitedslots">
                    Hurry Up, Limited slots left!
                  </div>
                </div>
              </div>
            </div>
          )}

          {!changeView ? (
            <div
              className="slider home-packages"
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "0px",
              }}
              id="scrollable-div2-mobile"
            >
              <div className={classes.horizontalScroll}>
                <Link to="/dog-running" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-walking-package-1-header">
                      <div className="unfaded-title">ONCE A DAY, ONE PET</div>
                      <div className="home-package-subtitle">
                        Monthly | 26 Sessions
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>30 mins walk each, GPS Live</i>
                          </div>
                          <div>
                            <i> Tracking, Photo Updates...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-running" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-orange-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/one-pet-once.png"
                            alt="package icon"
                            className="packageicon"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-running" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-walking-package-2-header">
                      <div className="unfaded-title">TWICE A DAY, ONE PET</div>
                      <div className="home-package-subtitle">
                        Monthly | 52 Sessions
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>Recommended 60 mins each </i>
                          </div>
                          <div>
                            <i>day Overexcitement...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-running" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-pink-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/one-pet-twice.png"
                            alt="package icon"
                            className="packageicon"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-running" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-walking-package-1-header">
                      <div className="unfaded-title">ONCE A DAY, TWO PETS</div>
                      <div className="home-package-subtitle">
                        Monthly | 26 Sessions
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>30 mins walk each, GPS Live</i>
                          </div>
                          <div>
                            <i> Tracking, Photo Updates...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-running" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-orange-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/two-pets-once.png"
                            alt="package icon"
                            className="packageicon"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-running" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-walking-package-2-header">
                      <div className="unfaded-title">TWICE A DAY, TWO PETS</div>
                      <div className="home-package-subtitle">
                        Monthly | 52 Sessions
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>Recommended 60 mins each </i>
                          </div>
                          <div>
                            <i>day Overexcitement...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-running" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-pink-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/two-pets-twice.png"
                            alt="package icon"
                            className="packageicon"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>







              </div>
            </div>
          ) : (
            <>
              <button
                style={{
                  height: "150px",
                  borderRadius: "5px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderColor: "rgba(254, 119, 152, 1)",
                  border: "2px solid rgba(254,119,152,1)",
                  cursor: "pointer",
                  background: "none",
                }}
                onClick={leftScroll2}
              >
                <BsChevronLeft color="rgba(254,119,152,1)" size={20} />
              </button>
              <div
                className="slider-training-packages"
                id="scrollable-div2"
                style={{ alignItems: "center", scrollBehavior: "smooth" }}
              >
                <Link to="/dog-running" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-walking-package-1-header">
                      <div className="unfaded-desktop-title">
                        ONCE A DAY, ONE PET
                      </div>
                      <div className="home-package-desktop-subtitle">
                        Monthly | 26 Sessions
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>30 mins walk each, GPS Live</i>
                          </div>
                          <div>
                            <i> Tracking, Photo Updates...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-running" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-orange-arrow.png"
                                className="mini-card-arrow-icon-desktop"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/one-pet-once.png"
                            alt="package icon"
                            className="packageicon-desktop1"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-running" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-walking-package-2-header">
                      <div className="unfaded-desktop-title">
                        TWICE A DAY, ONE PET
                      </div>
                      <div className="home-package-desktop-subtitle">
                        Monthly | 52 Sessions
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-desktop-content-left-upper">
                          <div>
                            <i>Recommended 60 mins each </i>
                          </div>
                          <div>
                            <i>day Overexcitement...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-running" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-pink-arrow.png"
                                className="mini-card-arrow-icon-desktop"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/one-pet-twice.png"
                            alt="package icon"
                            className="packageicon-desktop1"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-running" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-walking-package-1-header">
                      <div className="unfaded-desktop-title">
                        ONCE A DAY, TWO PETS
                      </div>
                      <div className="home-package-desktop-subtitle">
                        Monthly | 26 Sessions
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-desktop-content-left-upper">
                          <div>
                            <i>30 mins walk each, GPS Live</i>
                          </div>
                          <div>
                            <i> Tracking, Photo Updates...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-running" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-orange-arrow.png"
                                className="mini-card-arrow-icon-desktop"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/two-pets-once.png"
                            alt="package icon"
                            className="packageicon-desktop1"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dog-running" state={{ from: "home" }} style={{ textDecoration: "none" }}>
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-walking-package-2-header">
                      <div className="unfaded-desktop-title">
                        TWICE A DAY, TWO PETS
                      </div>
                      <div className="home-package-desktop-subtitle">
                        Monthly | 52 Sessions
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-desktop-content-left-upper">
                          <div>
                            <i>Recommended 60 mins each </i>
                          </div>
                          <div>
                            <i>day Overexcitement...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/dog-running" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-pink-arrow.png"
                                className="mini-card-arrow-icon-desktop"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/two-pets-twice.png"
                            alt="package icon"
                            className="packageicon-desktop1"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>







              </div>
              <button
                style={{
                  height: "150px",
                  borderRadius: "5px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderColor: "rgba(254, 119, 152, 1)",
                  border: "2px solid rgba(254,119,152,1)",
                  cursor: "pointer",
                  background: "none",
                }}
                onClick={rightScroll2}
              >
                <BsChevronRight color="rgba(254,119,152,1)" size={20} />
              </button>
            </>
          )}
        </div>
        {!changeView ? (
          <div
            style={{ width: "100%", paddingLeft: "15px", paddingRight: "15px" }}
          >
            {/* <Slider min={0} max={100}  value={valueMobile2} onChange={handleChangeMobile2} tooltip={false} onChangeComplete={()=>setScroll3(true)}/> */}
            {/* <center style={{}}>
                <Loader color="#ff5e95" />
                </center> */}
          </div>
        ) : null}
      </div>

      {!changeView ? (
        <div
          className="bookingbutton"
          style={{
            width: "100%",
            paddingLeft: "30px",
            paddingRight: "30px",
            position: "relative",
            bottom: "20px",
          }}
        >
          <Link
            to="/dog-running"
            state={{ type: "dog-running" }}
            style={{ width: "100%" }}
          >
            <button
              className="booksession-mobile-view"
              style={{ width: "100%" }}
            >
              Book 1st FREE Walk
            </button>
          </Link>
          <div className="limitedslots">Hurry Up, Limited slots left!</div>
        </div>
      ) : null}

      {!changeView ? (
        <div className="container-home pink-container-family">
          <div className="textbox2">How to Get Started?</div>
          <hr className="vector1"></hr>
          <div>
            <TimelineComponent version={"mobile"} timelineData={timelineData} />
          </div>
        </div>
      ) : (
        <div>
          <div className="container-home-desktop">
            <div className={classes.getStartedHeading}>
              <div
                className={classes.howToStart}
                style={{ marginBottom: "0px" }}
              >
                <div className="textbox2-desktop">
                  <h3 className="fifty-font-size">How to Start?</h3>
                </div>
                <div className="line-div relative-line">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>

              <div
                className="container-home-desktop-subtext"
                style={{
                  marginTop: "0px",
                  position: "relative",
                  bottom: "20px",
                }}
              >
                <div>
                  <h4 className="twenty-font-size bold-none" style={{ marginTop: "2rem" }}>
                    It's as easy as 1-2-3, Follow the steps given and you are
                    good to proceed.
                  </h4>
                </div>
              </div>
            </div>
            <div>
              <TimelineComponent
                version={"desktop"}
                timelineData={timelineData}
              />
            </div>
          </div>
        </div>
      )}

      <div
        className={
          !changeView
            ? "sliderServiceContainer pink-container-family"
            : "sliderServiceContainer-desktop"
        }
      >
        {!changeView ? (
          <div>
            <div className="textbox2">Our Grooming Sessions</div>
            <hr className="vector2"></hr>
          </div>
        ) : null}

        <div className="slider-container">
          {!changeView ? null : (
            <div className={classes.landingGroomingContainer}>
              <div className={classes.landingGroomingHeading}>
                <div>
                  <div className={classes.leftHead}>
                    <Link to={"/grooming"} style={{ textDecoration: "none" }}>
                      <h2 className={classes.leftHead}>
                        Our Grooming Sessions{" "}
                      </h2>
                    </Link>
                  </div>
                  <div className="line-div relative-line">
                    <hr
                      className="live-training-pink-line"
                      style={{ position: "relative", top: "10px" }}
                    ></hr>
                  </div>
                </div>
              </div>

              <div
                className="home-page-walking-packages-desktop-container2"
                style={{ position: "relative", bottom: "10px" }}
              >
                <h4 className="bold-none" style={{ marginTop: "2rem" }}>
                  <div>Our grooming products are </div>
                  <div>carefully selected to care for your</div>
                  <div>pet's skin coat and are non-allergic.</div>
                </h4>
              </div>

              <div
                className={classes.gettingStartedBookingBtn1}
                style={{ position: "relative", bottom: "10px" }}
              >
                <div className="bookingbutton">
                  {/* <Link to="/grooming" state={{ type: "grooming" }}> */}
                  <button className="booksession" onClick={() => scrollToTop()}>
                    Book Now
                  </button>
                  {/* </Link> */}
                  <div className="limitedslots">
                    Hurry Up, Limited slots left!
                  </div>
                </div>
              </div>
            </div>
          )}

          {!changeView ? (
            <div className="slider home-packages" id="scrollable-div3-mobile">
              <div className={classes.horizontalScroll}>
                <Link
                  to="/grooming"
                  state={{ from: "home" }}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-grooming-package-1-header">
                      <div className="unfaded-title">BATH & BRUSH</div>
                      <div className="home-package-subtitle">
                        Best for Puppies
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>Includes Bath, Shampoo, Blow Dry</i>
                          </div>
                          <div>
                            <i>Brushing, Sanitary Trim...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/grooming" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-blue-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-purple-icon.png"
                            alt="package icon"
                            className="packageicon-grooming"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/grooming"
                  state={{ from: "home" }}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-grooming-package-2-header">
                      <div className="unfaded-title">BATH & BRUSH</div>
                      <div className="home-package-subtitle">
                        <i>(With Tick Treatement)</i>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>Includes Bath, Shampoo, Blow Dry</i>
                          </div>
                          <div>
                            <i>Brushing, Sanitary Trim...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/grooming" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-pink-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-pink-icon.png"
                            alt="package icon"
                            className="packageicon-grooming"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/grooming"
                  state={{ from: "home" }}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-grooming-package-3-header">
                      <div className="unfaded-title">HAIRCUT & STYLING</div>
                      <div className="home-package-subtitle">
                        Best for all Pets
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>Full Haircut, Shaving , Hairstyling</i>
                          </div>
                          <div>
                            <i>Sanitary trim...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/grooming" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-purple-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-purple.png"
                            alt="package icon"
                            className="packageicon-grooming"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/grooming"
                  state={{ from: "home" }}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="slide home-training-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-grooming-package-4-header">
                      <div className="unfaded-title">BATH & FULL HAIRCUT</div>
                      <div className="home-package-subtitle">Most Popular</div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-content-left-upper">
                          <div>
                            <i>Anal Gland Cleaning, Scissoring Feet</i>
                          </div>
                          <div>
                            <i> Shaving Pads...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          <Link to="/grooming" state={{ from: "home" }}>
                            <LazyLoad>
                              <img
                                alt="arrowIcon"
                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-green-arrow.png"
                                className="mini-card-arrow-icon"
                              />
                            </LazyLoad>
                          </Link>
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-green-icon.png"
                            alt="package icon"
                            className="packageicon-grooming-4"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ) : (
            <>
              <button
                style={{
                  height: "150px",
                  borderRadius: "5px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderColor: "rgba(254, 119, 152, 1)",
                  border: "2px solid rgba(254,119,152,1)",
                  cursor: "pointer",
                  background: "none",
                }}
                onClick={leftScroll3}
              >
                <BsChevronLeft color="rgba(254,119,152,1)" size={20} />
              </button>
              <div
                className="slider-training-packages"
                id="scrollable-div3"
                style={{ alignItems: "center", scrollBehavior: "smooth" }}
              >
                <Link
                  to="/grooming"
                  state={{ from: "home" }}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-grooming-package-1-header">
                      <div className="unfaded-desktop-title">BATH & BRUSH</div>
                      <div className="home-package-desktop-subtitle">
                        Best for Puppies
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-desktop-content-left-upper">
                          <div>
                            <i>Includes Bath, Shampoo, Blow Dry</i>
                          </div>
                          <div>
                            <i>Brushing, Sanitary Trim...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          {/* <Link to="/grooming" state={{from: "home"}}> */}
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-blue-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                          {/* </Link> */}
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-purple-icon.png"
                            alt="package icon"
                            className="packageicon-grooming-desktop"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/grooming"
                  state={{ from: "home" }}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-grooming-package-2-header">
                      <div className="unfaded-desktop-title">BATH & BRUSH</div>
                      <div className="home-package-desktop-subtitle">
                        <i>(With Tick Treatement)</i>
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-desktop-content-left-upper">
                          <div>
                            <i>Includes Bath, Shampoo, Blow Dry</i>
                          </div>
                          <div>
                            <i>Brushing, Sanitary Trim...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          {/* <Link to="/grooming" state={{from: "home"}}> */}
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-pink-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                          {/* </Link> */}
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-pink-icon.png"
                            alt="package icon"
                            className="packageicon-grooming-desktop"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/grooming"
                  state={{ from: "home" }}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-grooming-package-3-header">
                      <div className="unfaded-desktop-title">
                        HAIRCUT & STYLING
                      </div>
                      <div className="home-package-desktop-subtitle">
                        Best for all Pets
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-desktop-content-left-upper">
                          <div>
                            <i>Full Haircut, Shaving , Hairstyling</i>
                          </div>
                          <div>
                            <i>Sanitary trim...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          {/* <Link to="/grooming" state={{from: "home"}}> */}
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-purple-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                          {/* </Link> */}
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-purple.png"
                            alt="package icon"
                            className="packageicon-grooming-desktop"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/grooming"
                  state={{ from: "home" }}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="slide home-training-desktop-package-1"
                    style={{ animation: "scroll-div10 2s linear" }}
                  >
                    <div className="home-grooming-package-4-header">
                      <div className="unfaded-desktop-title">
                        BATH & FULL HAIRCUT
                      </div>
                      <div className="home-package-desktop-subtitle">
                        Most Popular
                      </div>
                    </div>

                    <div className="mini-package-cards-content-container">
                      <div className="mini-package-cards-content-left">
                        <div className="mini-package-cards-desktop-content-left-upper">
                          <div>
                            <i>Anal Gland Cleaning, Scissoring Feet</i>
                          </div>
                          <div>
                            <i> Shaving Pads...& more</i>
                          </div>
                        </div>
                        <div className="mini-package-cards-content-left-lower">
                          {/* <Link to="/grooming" state={{from: "home"}}> */}
                          <LazyLoad>
                            <img
                              alt="arrowIcon"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-green-arrow.png"
                              className="mini-card-arrow-icon-desktop"
                            />
                          </LazyLoad>
                          {/* </Link> */}
                        </div>
                      </div>

                      <div className="mini-package-cards-content-right">
                        <LazyLoad>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/grooming-green-icon.png"
                            alt="package icon"
                            className="packageicon-grooming-4-desktop"
                          ></img>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <button
                style={{
                  height: "150px",
                  borderRadius: "5px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderColor: "rgba(254, 119, 152, 1)",
                  border: "2px solid rgba(254,119,152,1)",
                  cursor: "pointer",
                  background: "none",
                }}
                onClick={rightScroll3}
              >
                <BsChevronRight color="rgba(254,119,152,1)" size={20} />
              </button>
            </>
          )}
        </div>
        {!changeView ? (
          <div
            style={{ width: "100%", paddingLeft: "15px", paddingRight: "15px" }}
          >
            {/* <center style={{}}>
                <Loader color="#ff5e95" />
                </center> */}
            {/* <Slider min={0} max={100}  value={valueMobile3} onChange={handleChangeMobile3} tooltip={false} onChangeComplete={()=>setScroll5(true)}/> */}
          </div>
        ) : null}
      </div>

      {!changeView ? (
        <div
          className="bookingbutton"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        >
          {/* <Link to="/grooming" state={{ type: "grooming" }}> */}
          <button
            className="booksession-mobile-view"
            onClick={() => scrollToTop()}
            style={{ width: "100%", marginTop: "0px" }}
          >
            Book Now
          </button>
          {/* </Link> */}
          <div className="limitedslots">Hurry Up, Limited slots left!</div>
        </div>
      ) : null}

      <div
        className={
          !changeView ? "container pink-container-family" : "container-desktop"
        }
      >
        {!changeView ? (
          <div>
            <div className="textbox2">Words From Pet Parents</div>
            <hr className="vector2"></hr>
          </div>
        ) : null}

        <div class="review-container">
          <div class={!changeView ? "review-box" : "review-box-desktop"}>
            {!changeView ? null : (
              <div className="home-page-customers-head">
                <div className="home-page-customer-heading2">
                  <div>
                    <h3 className="fifty-font-size">Our Customers</h3>
                  </div>
                  <div className="line-div relative-line">
                    <hr className="live-training-pink-line"></hr>
                  </div>
                </div>

                <div
                  className="home-page-customers-contents"
                  style={{ position: "relative", bottom: "20px" }}
                >
                  <h4 className="bold-none" style={{ marginTop: "2rem" }}>
                    <div>Our customers trust us for having</div>
                    <div>
                      <span className="pink">Joyful</span> and{" "}
                      <span className="pink">Positive</span> Reinforcements
                    </div>
                    <div>only, "Your Pet is Our Family!"</div>
                  </h4>
                </div>
              </div>
            )}

            <div
              id="testimonialContainer"
              className={!changeView ? "review-box" : "review-box-desktop-3"}
            >
              <div
                className={
                  !changeView
                    ? "testimonial-card-mastercontainer-mobile-view"
                    : "testimonial-card-mastercontainer-2"
                }
              >
                <div className="testimonial-card-upper-container">
                  <div className="testimonial-card-quote-image">
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/quote-icon.png"
                      alt="quoteIcon"
                      className="quote-icon"
                    ></img>
                  </div>
                  <div className="testimonial-card-review-content">
                    {testmonialData.message}
                  </div>
                  <hr className="grey-line"></hr>
                </div>
                <div className="testimonial-card-lower-container">
                  <div className="testimonial-card-reviewer-image">
                    <img
                      alt="rudrangImage"
                      className="testimonial-user-image"
                      src={testmonialData.img_src}
                    />
                  </div>
                  <div className="testimonial-card-reviewer-name">
                    {testmonialData.name}
                  </div>
                  <div className="testimonial-card-reviewer-profession">
                    {testmonialData.profession}
                  </div>
                  <div className="testimonial-card-review-star-box">
                    <div>
                      <img
                        alt="starIcon"
                        className="star-icon-desktop-image"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                      />
                    </div>
                    <div>
                      <img
                        alt="starIcon"
                        className="star-icon-desktop-image"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                      />
                    </div>
                    <div>
                      <img
                        alt="starIcon"
                        className="star-icon-desktop-image"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                      />
                    </div>
                    <div>
                      <img
                        alt="starIcon"
                        className="star-icon-desktop-image"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                      />
                    </div>
                    <div>
                      <img
                        alt="starIcon"
                        className="star-icon-desktop-image"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={!changeView ? "sliderServiceContainer" : ""}
        style={{ marginTop: "" }}
      >
        {!changeView ? (
          <div>
            <div className="textbox2">Guide To Pet Parenting</div>
            <hr className="vector2"></hr>
          </div>
        ) : null}

        {!changeView ? (
          <ServiceBenefits view={"mobile"} />
        ) : (
          <div className="dog-container-desktop">
            <div className="dog-slide-desktop-grooming">
              <div
                className="grooming-page-behave-box1"
                style={{ marginBottom: "0px" }}
              >
                <div>
                  <h2 className="fifty-font-size">Guide To Pet Parenting</h2>
                </div>
                <div className="line-div relative-line">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>

              <div
                className="grooming-page-behave-box2"
                style={{
                  marginTop: "0px",
                  position: "relative",
                  bottom: "25px",
                }}
              >
                <h4 className="bold-none" style={{ marginTop: "2rem" }}>
                  <div>A Professionally Trained Dog is </div>
                  <div>well behaved and Happy.</div>
                  <div>Read our Dog Training blogs for more.</div>
                </h4>
              </div>
              <div
                className="training-page-behave-box3"
                style={{
                  marginTop: "0px",
                  position: "relative",
                  bottom: "30px",
                }}
              >
                We Can <span className="pink">Help!</span>
              </div>
            </div>
            <div className="dog-box-desktop-training">
              <div className="educational-content-card-training" >
                <div className="educational-content-card-training-upper" >
                  <img
                    className="educational-content-card-training-image"
                    alt="eduCard"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/road-trip-with-dog.png"
                  />
                  <div className="educational-content-card-training-text">
                    Blog
                  </div>
                  <div className="educational-content-card-training-head">
                    Road Tripping With Your Dog
                  </div>
                </div>
                <div className="educational-content-card-training-lower">
                  <div className="">
                    <Link
                      className="educational-content-card-training-link"
                      to="/blog/road-tripping-with-your-dog"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>

              <div className="educational-content-card-training">
                <div className="educational-content-card-training-upper">
                  <img
                    className="educational-content-card-training-image"
                    alt="eduCard"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/positive-pet-rewards.png"
                  />
                  <div className="educational-content-card-training-text">
                    Blog
                  </div>
                  <div className="educational-content-card-training-head">
                    Positive Pet Rewards
                  </div>
                </div>
                <div className="educational-content-card-training-lower">
                  <div className="">
                    <Link
                      className="educational-content-card-training-link"
                      to="/blog/positive-pet-rewards"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>

              <div className="educational-content-card-training">
                <div className="educational-content-card-training-upper">
                  <img
                    className="educational-content-card-training-image"
                    alt="eduCard"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pet-separation.png"
                  />
                  <div className="educational-content-card-training-text">
                    Blog
                  </div>
                  <div className="educational-content-card-training-head">
                    Pet Separation Anxiety
                  </div>
                </div>
                <div className="educational-content-card-training-lower">
                  <div className="">
                    <Link
                      className="educational-content-card-training-link"
                      to="/blog/pet-separation-anxiety"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>

              <div className="educational-content-card-training">
                <div className="educational-content-card-training-upper">
                  <img
                    className="educational-content-card-training-image"
                    alt="eduCard"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/guide-to-pet-foods.png"
                  />
                  <div className="educational-content-card-training-text">
                    Blog
                  </div>
                  <div className="educational-content-card-training-head">
                    Guide to Pet Foods
                  </div>
                </div>
                <div className="educational-content-card-training-lower">
                  <div className="">
                    <Link
                      className="educational-content-card-training-link"
                      to="/blog/guide-to-pet-foods"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>

              <div className="educational-content-card-training">
                <div className="educational-content-card-training-upper">
                  <img
                    className="educational-content-card-training-image"
                    alt="eduCard"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/puppy-and-teething.png"
                  />
                  <div className="educational-content-card-training-text">
                    Blog
                  </div>
                  <div className="educational-content-card-training-head">
                    Puppy and Teething
                  </div>
                </div>
                <div className="educational-content-card-training-lower">
                  <div className="">
                    <Link
                      className="educational-content-card-training-link"
                      to="/blog/puppy-and-teething"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {!changeView ? (
        <FAQContainer view={"mobile"} />
      ) : (
        <FAQContainer view={"desktop"} />
      )}

      {!changeView ? <Footer view={"mobile"} /> : <Footer view={"desktop"} />}

      {!changeView ? null : (
        <div>
          <div className="desktop-footer-buttons-container-add" style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ fontWeight: "800" }}>MANGOPAW TECHNOLOGIES AND SOLUTIONS PRIVATE LIMITED</p>
            <p > Ph no- +918889991800</p>
          </div>
          <div className="desktop-footer-buttons-container">
            <div className="desktop-footer-buttons-left">
              <div className="desktop-footer-buttons-left-btn1">
                <a
                  href="https://play.google.com/store/apps/details?id=in.tamely.user"
                  target="_blank" rel="nofollow"
                >
                  <img
                    alt="icon"
                    className="desktop-footer-buttons-left-btn1-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                  />
                </a>
              </div>
              <div className="desktop-footer-buttons-left-btn1">
                <a
                  href="https://apps.apple.com/in/app/petmojo/id1609861328"
                  target="_blank" rel="nofollow"
                >
                  <img
                    alt="icon"
                    className="desktop-footer-buttons-left-btn1-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png"
                  />
                </a>
              </div>
              <div
                onClick={scrollToTop}
                className="desktop-footer-buttons-left-btn3"
              >
                <div>Go at the top</div>

                <svg
                  className="top-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  />
                </svg>
              </div>
            </div>

            <div className="desktop-footer-buttons-right">
              Copyright 2024 Petmojo
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LandingPage;
