import { useState } from "react";

const DogTrainingPackageCard = ({ data }) => {
    const [showMore, setShowMore] = useState(false);
    return (
        <div className="training-page-mobile-view-package-container" style={{ animation: "scroll-div10 2s linear", overflowY: showMore ? "scroll" : "hidden" }}>
            <div className="training-page-mobile-view-package-sec-1">
                <img
                    alt="trainingIcon"
                    className="training-page-mobile-view-packageIcon"
                    src={data.src}
                />
            </div>
            <div className={`training-page-mobile-view-package-sec-2-2 ${data.linearGradient} my-2`}>
                {data.packageName}
            </div>
            {data?.stayIncluded &&
                <div className="font-bold italic text-[14px]" style={{ color: "#0B5044" }}>{"(Food & Stay Included)"}</div>
            }
            <div className="training-page-mobile-view-package-sec-3">
                <div className="training-page-mobile-view-package-sec-3-1">
                    {`${data.noOfSessions} | ${data.duration}`}
                </div>

                <div className="training-page-mobile-view-package-section3">
                    {/* <div className="training-page-mobile-view-package-sec-3-o">
                            <span className="package-bold">
                              All Sessions from PUPPY TRAINING+
                            </span>
                          </div> */}
                    {data.benefits.map((item, idx) => (
                        <div key={idx} className="training-page-mobile-view-package-sec-3-o">
                            <span className="package-bold">{item}</span>
                        </div>
                    ))}
                    {data.moreBenefits.length > 0 &&
                        <div style={{ textAlign: "right", color: "rgb(129,13,144)", display: showMore ? "none" : "block" }} className="cursor-pointer" onClick={() => setShowMore(true)} >
                            show more...
                        </div>
                    }
                    {showMore && <>
                        {data.moreBenefits.map((item, idx) => (
                            <div key={idx} className="training-page-mobile-view-package-sec-3-o">
                                <span className="package-bold">{item}</span>
                            </div>
                        ))}
                    </>}
                    <div style={{ textAlign: "right", color: "rgb(129,13,144)", display: !showMore ? "none" : "block" }} className="cursor-pointer" onClick={() => setShowMore(false)} >
                        show less...
                    </div>
                </div>
            </div>

            <div className="" style={{ position: !showMore ? "absolute" : "relative", bottom: "15px", width: "100%" }}>
                <div className="training-card-mobile-view-discounted-price">
                    Discounted Price
                </div>
                <div className="training-card-mobile-view-price-btn" style={{ width: "100%", display: 'flex', justifyContent: "center", gap: "5px" }}>
                    <div className="training-card-mobile-view-og-price">
                        <div className="diagonal-line-mobile-view-training">
                            <div className="diagonal-line-mobile-view-text">
                                {`₹${data.originalPrice}`}
                            </div>
                        </div>{" "}
                    </div>
                    <div className="training-card-mobile-view-disc-price">
                        {`₹${data.discountedPrice}`}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DogTrainingPackageCard