import "./MobileFormsCSS.css";
import { useState, useEffect, useRef } from "react";
import LazyLoad from "react-lazyload";
import axios from "axios";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
import { Link, useNavigate, useLocation } from "react-router-dom";
import iconsMobile from "../../../images/iconsMobile.png";
import DeleteIcon from "../../../images/deleteIcon.png";
import CorruptIcon from "../../../images/CorruptIcon.png"



const TrainingPageForm = (props) => {
  const navigate = useNavigate();
  const sectionRef = useRef(null);

  const trainingPhoneRef = useRef(0);
  const petRef = useRef("");
  const trainingAddressRef = useRef("");
  const trainingDateRef = useRef("");

  const location = useLocation().pathname;

  const [trainingPhoneValidation, setTrainingPhoneValidation] = useState(true);
  const [petValidation, setPetValidation] = useState(true);
  const [trainingAddressValidation, setTrainingAddressValidation] =
    useState(true);
  const [trainingDateValidation, setTrainingDateValidation] = useState(true);
  const [trainingLoading, setTrainingLoading] = useState(false);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [address, setAddress] = useState("");
  // const [location, setLocation] = useState('/deleteacc');
  const [phoneOrEmail, setPhoneOrEmail] = useState('');
  // const [phoneValidation, setPhoneValidation] = useState(true); // Assuming it's initially valid
  // const [loading, setLoading] = useState(false);
  // const phoneRef = useRef(null);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);

  function scrollToSection() {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
    
  }

  async function trainingSubmissionHandler() {
    const inputPhone = trainingPhoneRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    let UTM_Source,
      UTM_Medium,
      UTM_Campaign = "";

   
    //   handleLocationAccess();

    const urlParams = new URLSearchParams(window.location.search);
    UTM_Source = urlParams.get("utm_source");
    UTM_Medium = urlParams.get("utm_medium");
    UTM_Campaign = urlParams.get("utm_campaign");

    let newLocal = address;
    if (inputPhone.toString().length === 10) {
      // handleLocationAccess();
      setTrainingLoading(true);
      const data = {
        data: {
          ContactNo: inputPhone,
          DateOfRegistration: dateOfReg,
          TimeOfRegistration: timeOfReg,
          Address: "",
          location: address,
          FormType: "Direct Form",
          UTM_Source: UTM_Source || "",
          UTM_Medium: UTM_Medium || "",
          UTM_Campaign: UTM_Campaign || "",
          TrialDate: "",
          location: address,
        },
      };

      await axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveTrainingData",
          data
        )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
      setTrainingLoading(false);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Training Registration Fired From Mobile Service Page Form`,
      });
      navigate("/thankPage");
    } else {
      if (inputPhone.toString().length !== 10) {
        setTrainingPhoneValidation(false);
        trainingPhoneRef.current.value = "";
      }

      return;
    }
  }

  const formShiftHandler = () => {
    window.scrollTo(0, 400);
  };

  return (
    <div className="training-page-mobile-view-form-container">
      {/* part 1 */}
      {location === "/deleteacc" && !isDeleteConfirmed && (
        <>
      <div
        className="training-page-mobile-view-form-header"
        onClick={scrollToSection}
      >
        <div>Enter your Details</div>
      </div>
      <div ref={sectionRef}>
        <div className="training-page-mobile-view-form-phone-container">
          <div className="training-page-mobile-view-phone-check-container">
            <div>Phone Number / Email</div>
            {trainingPhoneValidation ? null : (
              <div className="home-page-walking-form-mobile-feedback">
                Please enter a valid number / email
              </div>
            )}
          </div>
          <input
            ref={trainingPhoneRef}
            onChange={() => setTrainingPhoneValidation(true)}
            onClick={() => formShiftHandler()}
            type="number"
            className="training-page-mobile-view-input"
            placeholder="Enter phone number / email"
            style={{ fontSize: "17px" }}
          />
        </div>
        
        <div className="training-page-mobile-view-form-button">
          {trainingLoading ? (
            <LoadingSpinner />
          ) : (
            <div
              className="bookingbutton"
              style={{
                width: "100%",
                paddingLeft: "30px",
                paddingRight: "30px",
                marginTop: "10px",
                padding:"0 0 1rem 0" 
              }}
            >
              <button
                 onClick={() => setIsDeleteConfirmed(true)}
                className="booksession-mobile-form"
                style={{ width: "100%"}}
              >
                Delete
              </button>
            </div>
          )}
        </div>
        
      </div>
      </>
      )}
      {/* part 1 ends */}

{/* part 2 starts */}
{location === "/deleteacc" && isDeleteConfirmed && (
        <>
      <div
        className="training-page-mobile-view-form-header"
        onClick={scrollToSection}
      >
        <div>Enter your Details</div>
      </div>
      <div ref={sectionRef}>
        <div className="training-page-mobile-view-form-phone-container">
          <div className="training-page-mobile-view-phone-check-container">
          
        
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              justifyContent:"center"
            }}
          >
            <img
              src={DeleteIcon}
              style={{ width: "5rem", height: "5rem", margin: "0 1rem" }}
            />
            <p
              style={{
                color: "#ef7348",
                fontFamily: "Lato",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: "700",
              }}
            >
              Your account will be deleted in 7 business days
            </p>
          </div>
        </div>
            
        </div>
        
        <div className="training-page-mobile-view-form-button">
        <div
              className="bookingbutton"
              style={{
                width: "100%",
                paddingLeft: "30px",
                paddingRight: "30px",
                marginTop: "10px",
                padding:"0 0 1rem 0" 
              }}
            >
              <button
                onClick={() => trainingSubmissionHandler()}
                className="booksession-mobile-form"
                style={{ width: "70%"}}
              >
                okay
              </button>
            </div>
        </div>
        </div>
        </>
      )}
        {/* part 2 ends */}




{/* part 3 */}
        {/* <div
        className="training-page-mobile-view-form-header"
        onClick={scrollToSection}
      >
        <div>Search Client Details</div>
      </div>
      <div ref={sectionRef}>
        <div className="training-page-mobile-view-form-phone-container">
          <div className="training-page-mobile-view-phone-check-container">
          
        
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              //   padding: "0 10rem",
            }}
          >
            <img
              src={CorruptIcon}
              style={{ width: "5rem", height: "5rem", margin: "0 1rem" }}
            />
            <p
              style={{
                color: "#ef7348",
                fontFamily: "Lato",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: "700",
                // paddingRight: "8rem",
              }}
            >
              Client Data not available!
            </p>
          </div>
        </div>
            
        </div>
        
        <div className="training-page-mobile-view-form-button">
        <div
              className="bookingbutton"
              style={{
                width: "100%",
                paddingLeft: "30px",
                paddingRight: "30px",
                marginTop: "10px",
                padding:"0 0 1rem 0" 
              }}
            >
              <button
                onClick={() => trainingSubmissionHandler()}
                className="booksession-mobile-form"
                style={{ width: "70%"}}
              >
               Search Other
              </button>
            </div>
        </div>
        </div> */}

    </div>
  );
};

export default TrainingPageForm;
