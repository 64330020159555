import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
// import HomePageGroomingForm from './HomePageGroomingForm.js';
import dogsIcon from "../../../images/dogsIcon.png";
import locationIcon from "../../../images/locationIcon.png";
import HomePageGroomingForm from "./GroomingPageFormNew";
import HomePageWalkingPage from "./WalkingPageForm";

const HomePageForm = (props) => {
  const navigate = useNavigate();

  const [current, setCurrent] = useState("training");

  const [loading, setLoading] = useState(false);

  const date = new Date();

  // ------------------------ validation states declarations --------------------
  // const [phoneValidation, setPhoneValidation] = useState(true);
  const [petValidation, setPetValidation] = useState(true);
  const [petNameValidation, setNamePetValidation] = useState(true);
  const [cityValidation, setCityValidation] = useState();
  const [phoneValidation, setPhoneValidation] = useState(true);
  const [dateValidation, setDateValidation] = useState(true);
  const [addressValidation, setAddressValidation] = useState(true);
  const [petName, setPetName] = useState("");
  // declaration of refs
  const phoneRef = useRef(0);
  const dateRef = useRef("");
  const addressRef = useRef("");
  const timeRef = useRef("");
  const emailRef = useRef("");
  const newAddressRef = useRef("");

  // ---------------- grooming container  --------------------
  const groomingDateRef = useRef("");
  const groomingTimeRef = useRef("");
  const groomingAddressRef = useState("");
  const groomingPhoneRef = useState(0);
  const [city, setCity] = useState("");
  const [selectedService, setSelectedService] = useState("BATH & BRUSH");
  const [serviceDropdown, setServiceDropdown] = useState(false);

  const [groomingPhoneValidation, setGroomingPhoneValidation] = useState(true);
  const [groomingAddressValidation, setGroomingAddressValidation] =
    useState(true);
  const [groomingDateValidation, setGroomingDateValidation] = useState(true);
  const [groomingTimeValidation, setGroomingTimeValidation] = useState(true);
  const [groomingEmailValidation, setGroomingEmailValidation] = useState(true);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [address, setAddress] = useState("");

  const [paymentData, setPaymentData] = useState({});
  const [flag, setFlag] = useState(false);
  const [startValue, setStartValue] = useState(false);
  const [startValue1, setStartValue1] = useState(false);
  const [startValue2, setStartValue2] = useState(false);

  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  var day = ("0" + currentDate.getDate()).slice(-2);
  var formattedDate = year + "-" + month + "-" + day;
  function setSelectedServiceHandler(service) {
    setSelectedService(service);
    setServiceDropdown(false);
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const handleCityChange = (event) => {
    setCity(event.target.value);

    if (city !== "") setCityValidation(true);
    else setCityValidation(false);
  };

  const handlePetNameChange = (event) => {
    setPetName(event.target.value);

    if (petName.trim() !== "") setNamePetValidation(true);
    else setNamePetValidation(false);
  };

  async function displayRazorpay(
    serviceAmount,
    inputPhone,
    SelectedService,
    DateOfReg,
    InputAddress
  ) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPayOrderId",
      {
        amount: serviceAmount,
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Petmojo",
      description: "Pet Grooming Payment",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        //console.log("Grooming Page Function: ", data);

        const result = await axios.post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPaySuccess",
          data
        );
        //alert(result.data.msg);
        const Data = {
          ...result.data,
          SelectedService,
          DateOfReg,
          InputAddress,
        };

        const actualAmount = serviceAmount / 100;

        const dataToStore = {
          Date: DateOfReg,
          Address: InputAddress,
          Contact_No: inputPhone.toString(),
          Amount_Paid: actualAmount.toString(),
          Selected_Service: SelectedService,
          Order_id: order_id,
        };

        await axios
          .post(
            "https://api.apispreadsheets.com/data/hq3CiEAYcPfNXIJE/",
            dataToStore
          )
          .then((response) => {
            console.log(response);
            console.log("The grooming payment has been completed!");
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in submitting the grooming payment to backend!"
            );
          });

        setPaymentData(Data);
        setFlag(true);
      },
      // prefill: {
      //     name: "Petmojo",
      //     email: "customercare@petmojo.in",
      //     contact: "+91 9009004473",
      // },
      notes: {
        address: "Petmojo",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    //console.log(paymentObject);
    paymentObject.open();
  }

  useEffect(() => {
    if (flag) navigate("/ordersummary", { state: paymentData });
  }, [paymentData, flag]);

  async function groomingDataSubmitHandler(path) {
    const inputDate = dateRef.current.value;
    const inputTime = "00:00";
    const inputPhone = phoneRef.current.value;
    const inputEmail = emailRef.current.value;
    const inputAddress = addressRef.current.value;

    const enteredYear = parseInt(inputDate.substring(0, 4));
    const enteredMonth = parseInt(inputDate.substring(5, 7));
    const enteredDay = parseInt(inputDate.substring(8, 10));

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    const stringPhone = phoneRef.current.value.toString();

    if (
      inputAddress !== "" &&
      inputEmail !== "" &&
      stringPhone.length === 10 &&
      inputDate !== "" &&
      enteredYear >= date.getFullYear() &&
      enteredMonth >= date.getMonth() + 1
    ) {
      if (enteredMonth === date.getMonth() + 1) {
        if (enteredDay <= date.getDate()) {
          setGroomingDateValidation(false);
          return;
        }
      }

      setLoading(true);

      let UTM_Source,
        UTM_Medium,
        UTM_Campaign = "";

      const urlParams = new URLSearchParams(window.location.search);
      UTM_Source = urlParams.get("utm_source");
      UTM_Medium = urlParams.get("utm_medium");
      UTM_Campaign = urlParams.get("utm_campaign");

      const data = {
        ContactNo: inputPhone,
        Services: selectedService,
        Date: inputDate,
        Time: inputTime,
        Address: inputAddress,
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        Email: inputEmail,
        FormType: "Direct Form",
        UTM_Source: UTM_Source,
        UTM_Medium: UTM_Medium,
        UTM_Campaign: UTM_Campaign,
        location: address,
      };

      var amount = 0;

      if (selectedService === "BATH & BRUSH") {
        //kartik
        amount = 799;
      } else if (selectedService === "BATH & BRUSH (With Tick Treatment)") {
        amount = 1199;
      } else if (selectedService === "HAIRCUT & STYLING") {
        amount = 1249;
      } else if (selectedService === "BATH & FULL HAIRCUT") {
        amount = 1799;
      }
      data["Amount"] = amount;
      data["paymentStatus"] = 2;
      axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveGroomingData",
          data
        )
        .then((response) => {
          console.log(response);
          console.log("The grooming form is being submitted...");
        })
        .catch((err) => {
          console.log(err);
          console.log(
            "There was some error in submitting the grooming form..."
          );
        });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `${path} Registration Fired From Desktop Service Page Form`,
      });

      setLoading(false);

      displayRazorpay(
        amount,
        selectedService,
        dateOfReg,
        inputPhone,
        inputAddress
      );
      //navigate("/thankPage");
    } else {
      if (inputPhone.toString().length !== 10) {
        setGroomingPhoneValidation(false);
        phoneRef.current.value = "";
      }
      if (inputEmail === "") {
        setGroomingEmailValidation(false);
      }
      if (inputAddress === "") {
        setGroomingAddressValidation(false);
      }
      if (inputDate === "") {
        setGroomingDateValidation(false);
      }
      if (enteredYear < date.getFullYear()) {
        setGroomingDateValidation(false);
        return;
      }
      if (enteredMonth < date.getMonth() + 1) {
        setGroomingDateValidation(false);
        return;
      }

      return;
    }
  }

  // -------------------handler functions for form header switch-------------

  function walkingHandlerFunction() {
    setCurrent("walking");
  }

  function trainingHandlerFunction() {
    setCurrent("training");
  }

  function groomingHandlerFunction() {
    setCurrent("grooming");
    // document.getElementById('banner-homepage').style.marginTop="120px"
  }

  // --------------------form submission handler functions-----------------------

  // async function submitWalkingTrainingDataHandler(path){

  //     const inputDate = dateRef.current.value;
  //     const inputTime = timeRef.current.value;
  //     const inputPhone = phoneRef.current.value;
  //     const inputAddress = addressRef.current.value;
  //     let newLoc = "";

  //     let link = "";
  //     const elapsed = Date.now();
  //     const date = new Date(elapsed);
  //     const timeOfReg = `${date.toLocaleTimeString('en-US')}`;
  //     const dateOfReg = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;

  //     const enteredYear = parseInt(inputDate.substring(0, 4));
  //     const enteredMonth = parseInt(inputDate.substring(5, 7));
  //     const enteredDay = parseInt(inputDate.substring(8, 10));

  //     const stringPhone = phoneRef.current.value.toString();

  //     let UTM_Source, UTM_Medium,UTM_Campaign= "";

  //     //   handleLocationAccess();

  //     const urlParams = new URLSearchParams(window.location.search);
  //     UTM_Source = urlParams.get('utm_source');
  //     UTM_Medium = urlParams.get('utm_medium')
  //     UTM_Campaign = urlParams.get('utm_campaign')

  //     let data = {data:{
  //         ContactNo: inputPhone,
  //         DateOfRegistration: dateOfReg,
  //         TimeOfRegistration: timeOfReg,
  //         location:address,
  //         Address: inputAddress,
  //         TrialDate: inputDate,
  //         FormType: "Home Page Form",
  //         UTM_Source:UTM_Source || "",
  //         UTM_Medium:UTM_Medium || "",
  //         UTM_Campaign:UTM_Campaign || ""
  //       }};

  //     //   data["location"]=address;

  //      if(addressRef.current.value !== "" && stringPhone.length === 10 && dateRef.current.value !== "" && (enteredYear >= date.getFullYear()) && (enteredMonth >= date.getMonth()+1) ){

  //         if(enteredMonth === date.getMonth()+1){
  //             if(enteredDay < date.getDate()){
  //               setDateValidation(false);
  //               return;
  //             }
  //         }

  //         setLoading(true);
  //         if(path === "walking"){
  //             link = "https://api.apispreadsheets.com/data/QcQTxBvwdD8rfShs/";
  //         }
  //         else if(path === "training"){
  //             console.log("Submitting the training data! hey")
  //             link =  "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveTrainingData"
  //         }

  //         await axios.post(link, data)
  //         .then((response) => {
  //             console.log(response);
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         })

  //         window.dataLayer = window.dataLayer || [];
  //         window.dataLayer.push({
  //           "event": `${path} Registration Fired From Desktop Home Page Form`
  //         });

  //         setLoading(false);
  //         navigate("/thankPage");

  //      }else{
  //         if(stringPhone.length !== 10){
  //             setPhoneValidation(false);
  //         }
  //         if(inputAddress === ""){
  //             setAddressValidation(false)
  //         }
  //         if(inputDate === ""){
  //             setDateValidation(false);
  //         }
  //         if(enteredYear < date.getFullYear()){
  //             setDateValidation(false);
  //             return;
  //         }
  //         if(enteredMonth < date.getMonth()+1){
  //             setDateValidation(false);
  //             return;
  //         }
  //     }
  // };

  async function submitWalkingTrainingDataHandler(path) {
    let link = "";
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;

    const stringPhone = phoneRef.current.value.toString();

    let UTM_Source,
      UTM_Medium,
      UTM_Campaign = "";

    const urlParams = new URLSearchParams(window.location.search);
    UTM_Source = urlParams.get("utm_source");
    UTM_Medium = urlParams.get("utm_medium");
    UTM_Campaign = urlParams.get("utm_campaign");
    console.log(dateRef.current.value);
    let data = {
      data: {
        ContactNo: phoneRef.current.value,
        DateOfRegistration: dateRef.current.value || dateOfReg,
        location: "",
        TimeOfRegistration: timeOfReg,
        Address: "",
        FormType: "Direct Form",
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
        TrialDate: "",
        PetName: petName,
        City: city,
      },
    };

    if (
      addressRef !== "" &&
      stringPhone.length === 10 &&
      petName.trim() !== "" &&
      city !== ""
    ) {
      setLoading(true);
      if (path === "walking") {
        link = "https://api.apispreadsheets.com/data/QcQTxBvwdD8rfShs/";
      } else if (path === "training") {
        console.log("Submitting the training data!");
        link =
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveTrainingData";
      }

      await axios
        .post(link, data)
        .then((response) => {
          console.log("hey2", response);
        })
        .catch((err) => {
          console.log("hey3", err);
        });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `${path} Registration Fired From Desktop Service Page Form`,
      });

      phoneRef.current.value = "";
      setLoading(false);
      navigate("/thankPage");
    } else if (stringPhone.length !== 10) {
      setPhoneValidation(false);
      phoneRef.current.value = "";
    } else if (petName.trim() === "") {
      setNamePetValidation(false);
    } else {
      setCityValidation(false);
    }
  }

  // const handleLocationAccess = () => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           const latitude = position.coords.latitude;
  //           const longitude = position.coords.longitude;
  //           setLat(latitude);
  //           setLon(longitude);
  //           // Make an API call to get the address based on the user's location
  //           fetch(
  //             `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`
  //           )
  //             .then((response) => response.json())
  //             .then((data) => {
  //               console.log(data);
  //               setAddress(data.results[9].formatted_address);
  //             //   newLoc=data.results[0].formatted_address;

  //             })
  //             .catch((error) => console.error(error));
  //         },
  //         (error) => {
  //           console.error(error);
  //         }
  //       );
  //     } else {
  //       console.error("Geolocation is not supported by this browser.");
  //     }
  //   };

  return (
    <div className="home-page-desktop-intro-form-container" style={{ marginBottom: "2rem" }}>
      <div className="home-page-desktop-intro-form-header">
        <div
          onClick={trainingHandlerFunction}
          className={`home-page-desktop-intro-form-head1-first-train ${current === "training" ? "active-form" : ""
            }`}
        >
          Dog Training
        </div>
        <div
          onClick={walkingHandlerFunction}
          className={`home-page-desktop-intro-form-head1-first ${current === "walking" ? "active-form" : ""
            }`}
        >
          <span>Daily Dog Walking/Exercise</span>
        </div>
        <div
          onClick={groomingHandlerFunction}
          className={`home-page-desktop-intro-form-head1-last ${current === "grooming" ? "active-form" : ""
            }`}
        >
          Pet Grooming
        </div>
      </div>
      <div className="home-page-desktop-intro-form-contents">
        {current === "walking" ? <HomePageWalkingPage /> : null}

        {current === "training" ? (
          <div className="home-page-form-container">
            <div className="home-page-form-inner-container">
              <div className="home-page-form-inner-container-left">
                <div className="home-page-form-inner-container-left-subcontainer">
                  <div className="home-page-desktop-input-container">
                    <div>Phone Number</div>

                    {phoneValidation ? null : (
                      <div className="home-page-desktop-phone-validation-text">
                        Please enter a valid phone number
                      </div>
                    )}
                  </div>
                  <input
                    ref={phoneRef}
                    onChange={() => setPhoneValidation(true)}
                    className={
                      phoneValidation
                        ? "walking-form-input-phone"
                        : "walking-form-input-phone invalid"
                    }
                    type="number"
                    placeholder="Enter your phone number"
                    name="phone"
                  />
                </div>
                <div className="">
                  <div className="home-page-desktop-input-container">
                    <div className="select-city-container"> Select City{" "}
                      <img
                        src={locationIcon}
                        style={{
                          width: "15px",
                          height: "15px",
                          marginLeft: "2px",
                        }}
                      />{" "}</div>

                    {(cityValidation === undefined || cityValidation !== false) ? null : (
                      <div className="home-page-desktop-phone-validation-text">
                        Select City
                      </div>
                    )}
                  </div>
                  <div style={{ position: "relative" }}>
                    <select
                      // onChange={() => setPhoneValidation(true)}
                      // ref={phoneRef}
                      className="walking-page-phone-input"
                      name="city"
                      value={city}
                      // onChange={handleCityChange}
                      onChange={(event) => {
                        handleCityChange(event);
                        setCityValidation(true);
                      }}
                      required
                    >

                      <option value="" disabled selected>
                        Select City
                      </option>
                      <option value="Delhi NCR">Delhi NCR</option>
                      <option value="Mumbai Metropolitan City">Mumbai Metropolitan City</option>

                    </select>
                    {/* <input
                      ref={dateRef}
                      onChange={() => {
                        setDateValidation(true);
                        setStartValue1(true);
                      }}
                      className={
                        dateValidation
                          ? "walking-form-input"
                          : "walking-form-input invalid"
                      }
                      type="date"
                      placeholder="DD/MM/YYYY"
                      name="date"
                      value={
                        startValue1 ? dateRef.current.value : formattedDate
                      }
                    /> */}
                  </div>
                  {/* {console.log(dateRef.current.value,formattedDate)} */}
                </div>
              </div>
              <div className="home-page-form-inner-container-right">
                <div className="home-page-desktop-input-container">
                  <div className="select-city-container">Enter Pet Name{" "}
                    <img
                      src={dogsIcon}
                      style={{
                        width: "15px",
                        height: "15px",
                        marginLeft: "2px",
                      }}
                    />{" "}
                  </div>

                  {petNameValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Please enter petname
                    </div>
                  )}
                </div>
                <input
                  className="walking-page-phone-input"
                  type="text"
                  placeholder="Enter Pet Name"
                  name="name"
                  value={petName}
                  onChange={(event) => {
                    handlePetNameChange(event);
                    setNamePetValidation(true);
                  }}
                  required
                />
              </div>
            </div>
            <div
              className="home-page-form-bottom-container"
              style={{ justifyContent: "space-between" }}
            >
              <div
                className="home-page-intro-bottom-text"
                style={{ width: "40%" }}
              >
                <i>We are mission driven & a Family of Pet lovers</i>
              </div>
              <div
                className="home-page-intro-bottom-btn"
                style={{ width: "40%" }}
              >
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <button
                    onClick={() => {
                      submitWalkingTrainingDataHandler("training");
                      setAddress(newAddressRef.current.value);
                    }}
                    ref={newAddressRef}
                    className="booksession-form"
                    style={{ width: "92%", marginLeft: "2rem" }}
                  >
                    Book a FREE Session
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : null}

        {current !== "grooming" ? null : (
          <HomePageGroomingForm />
          //     <div className="home-page-desktop-intro-form-contents">
          //     <div className='home-page-form-container-grooming' >
          //         <div className='home-page-form-grooming-upper-1'>
          //             <div className='grooming-page-form-grooming-upper-left'>
          //                 <div onClick = {() => setServiceDropdown(!serviceDropdown)} className='grooming-page-desktop-view-service-form'>
          //                     <div>Select Service</div>
          //                     <div className='groming-page-desktop-view-services-container'>
          //                         <div className='grooming-page-mobile-view-selected-service'>{selectedService}</div>
          //                         <div className='home-page-form-mobile-view-dropdown-arrow'>
          //                             <svg className='grooming-page-form-mobile-view-dropdown-arrow-img' xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
          //                                 <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
          //                             </svg>
          //                         </div>
          //                     </div>
          //                     {
          //                         serviceDropdown ? (
          //                             <div className="grooming-page-mobile-view-service-drop-container">
          //                                 <div onClick={() => setSelectedServiceHandler("BATH & BRUSH")} className="grooming-page-desktop-view-drop-item">
          //                                     <div className="grooming-page-mobile-view-drop-item-text">BATH & BRUSH</div>
          //                                     <div className="grooming-page-mobile-view-drop-item-price">₹799</div>
          //                                 </div>
          //                                 <div onClick={() => setSelectedServiceHandler("BATH & BRUSH (With Tick Treatment)")} className="grooming-page-desktop-view-drop-item">
          //                                     <div className="grooming-page-mobile-view-drop-item-text">BATH & BRUSH<span><i>(With Tick Treatment)</i>&nbsp;&nbsp;</span></div>
          //                                     <div className="grooming-page-mobile-view-drop-item-price">₹1199</div>
          //                                 </div>
          //                                 <div onClick={() => setSelectedServiceHandler("HAIRCUT & STYLING")} className="grooming-page-desktop-view-drop-item">
          //                                     <div className="grooming-page-mobile-view-drop-item-text">HAIRCUT & STYLING</div>
          //                                     <div className="grooming-page-mobile-view-drop-item-price">₹1249</div>
          //                                 </div>
          //                                 <div onClick={() => setSelectedServiceHandler("BATH & FULL HAIRCUT")} className="grooming-page-desktop-view-drop-item">
          //                                     <div className="grooming-page-mobile-view-drop-item-text">BATH & FULL HAIRCUT</div>
          //                                     <div className="grooming-page-mobile-view-drop-item-price">₹1799</div>
          //                                 </div>
          //                             </div>
          //                         ) : null
          //                     }
          //                 </div>

          //                 <div className='intro-form-home-page-address-grooming-1'>
          //                     <div className='home-page-desktop-input-container'>
          //                             <div>
          //                                 Complete Address
          //                             </div>
          //                             {
          //                                 groomingAddressValidation ? (null) : (
          //                                     <div className='home-page-desktop-phone-validation-text'>
          //                                         Please enter your Home Address
          //                                     </div>
          //                                 )
          //                             }
          //                     </div>
          //                     <input onChange={() => setGroomingAddressValidation(true)} ref = {addressRef} className='walking-form-input-location-grooming' type="text" placeholder='House No. / Flat No. / Building' />
          //                 </div>
          //             </div>

          //             <div className='home-page-form-grooming-upper-right-1'>

          //                 <div className='grooming-page-desktop-session-date-container'>
          //                     <div className='home-page-desktop-input-container'>
          //                         <div>
          //                             Session Date
          //                         </div>
          //                         {
          //                             groomingDateValidation ? (null) : (
          //                                 <div className='home-page-desktop-phone-validation-text'>
          //                                     Required
          //                                 </div>
          //                             )
          //                         }
          //                     </div>
          //                     <input onChange={() => setGroomingDateValidation(true)} ref = {dateRef} className='home-page-form-grooming-date' type="date" placeholder='DD/MM/YYYY' name="date" />
          //                 </div>

          //                 <div className='intro-form-home-page-email-grooming'>
          //                     <div className='home-page-desktop-input-container'>
          //                             <div>
          //                                 Phone Number
          //                             </div>
          //                             {
          //                                 groomingPhoneValidation ? (null) : (
          //                                     <div className='home-page-desktop-phone-validation-text'>
          //                                         Please enter a valid Phone Number
          //                                     </div>
          //                                 )
          //                             }
          //                     </div>
          //                     <input onChange={() => setGroomingPhoneValidation(true)} ref = {phoneRef} className='walking-form-input-location-grooming' type="number" placeholder='Enter phone number' />
          //                 </div>

          //                 <div className='intro-form-home-page-email-grooming'>
          //                     <div className='home-page-desktop-input-container'>
          //                             <div>
          //                                 Email
          //                             </div>
          //                             {
          //                                 groomingEmailValidation ? (null) : (
          //                                     <div className='home-page-desktop-phone-validation-text'>
          //                                         Enter your email address
          //                                     </div>
          //                                 )
          //                             }
          //                     </div>
          //                     <input onChange={() => setGroomingEmailValidation(true)} ref = {emailRef} className='walking-form-input-location-grooming' type="email" placeholder='Enter email address' />
          //                 </div>
          //             </div>
          //         </div>

          //         <div className='grooming-page-form-bottom-container-1'>
          //             <div className='home-page-intro-bottom-text'><i>We are mission driven & a Family of Pet Lovers</i></div>
          //             <div className='home-page-intro-bottom-btn'>
          //                 {
          //                     loading ? (<LoadingSpinner/>) : (<button onClick={() => groomingDataSubmitHandler()} className="booksession-form">Pay and Book</button>)
          //                 }

          //             </div>
          //         </div>
          //     </div>
          // </div>
        )}
      </div>
    </div>
  );
};

export default HomePageForm;
