import axios from "axios";
import { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./MobileFormsCSS.css";
// import  DatePicker  from 'react-datepicker';
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
// import HomePageGroomingForm from "./HomePageGroomingForm.js";
import dogsIcon from "../../../images/dogsIcon.png";
import locationIcon from "../../../images/locationIcon.png";
import phoneIcon from "../../../images/telephoneIcon.png";
import HomePageBoardingForm from './BoardingPageForm';
import HomePageGroomingForm from './GroomingPageFormNew';
import WalkingPageForm from "./WalkingPageForm";


const HomePageForm = (props) => {
  // ---------------------- declaration of refs ----------------------------------------

  const navigate = useNavigate();

  const walkingPhoneRef = useRef(0);
  const walkingAddressRef = useRef("");
  const walkingDateRef = useRef("");

  const trainingPhoneRef = useRef(0);
  const trainingAddressRef = useRef("");
  const trainingDateRef = useRef("");

  const groomingPhoneRef = useRef(0);
  const groomingAddressRef = useRef("");
  const groomingDateRef = useRef("");

  const addressRef = useRef("");
  const startDateRef = useRef("");
  const endDateRef = useRef("");
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);

  // ---------------------- declaration of states ----------------------------------------

  const [showDropdown, setShowDropdown] = useState(false);
  const [activeHeader, setActiveHeader] = useState(
    "Book a FREE Training Session"
  );

  const [selectedFirst, setSelectedFirst] = useState(true);
  const [selectedSecond, setSelectedSecond] = useState(false);
  const [selectedThird, setSelectedThird] = useState(false);
  const [selectedNew, setSelectedNew] = useState(false);

  const [walkingPhoneValidation, setWalkingPhoneValidation] = useState(true);
  const [walkingAddressValidation, setWalkingAddressValidation] =
    useState(true);
  const [walkingDateValidation, setWalkingDateValidation] = useState(true);
  const [walkingLoading, setWalkingLoading] = useState(false);

  const [trainingPhoneValidation, setTrainingPhoneValidation] = useState(true);
  const [trainingAddressValidation, setTrainingAddressValidation] =
    useState(true);
  const [trainingDateValidation, setTrainingDateValidation] = useState(true);
  const [trainingLoading, setTrainingLoading] = useState(false);

  const [addressValidation, setAddressValidation] = useState(true);
  const [startDateValidation, setStartDateValidation] = useState(true);
  const [endDateValidation, setEndDateValidation] = useState(true);

  const [groomingPhoneValidation, setGroomingPhoneValidation] = useState(true);
  const [groomingAddressValidation, setGroomingAddressValidation] =
    useState(true);
  const [groomingDateValidation, setGroomingDateValidation] = useState(true);
  const [groomingLoading, setGroomingLoading] = useState(false);
  const [selectedService, setSelectedService] = useState("BATH & BRUSH");
  const [startValue, setStartValue] = useState(false);
  const [endValue, setEndValue] = useState(false);
  const [serviceDropdown, setServiceDropdown] = useState(false);

  const [paymentData, setPaymentData] = useState({});
  const [flag, setFlag] = useState(false);
  const [petName, setPetName] = useState("");
  const [city, setCity] = useState("");
  const [petNameValidation, setNamePetValidation] = useState(true);
  const [cityValidation, setCityValidation] = useState("")
  // const [trainingPhoneValidation, setTrainingPhoneValidation] = useState(true);
  const [petValidation, setPetValidation] = useState(true);

  const [activeForm, setActiveForm] = useState("training");
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  var day = ("0" + currentDate.getDate()).slice(-2);
  var formattedDate = year + "-" + month + "-" + day;
  // console.log(formattedDate);


  // ---------------------- declaration of effects ----------------------------------------

  // ---------------------- declaration of handler functions ----------------------------------------

  function dropdownHandler() {
    console.log("dropdownHandler was executed");
    setShowDropdown(!showDropdown);
  }

  function setSelectedServiceHandler(service) {
    setSelectedService(service);
    setServiceDropdown(false);
  }

  function changeActiveValue(value) {
    if (value === "walking") {
      setActiveHeader("Book a FREE Walking Session");
      setShowDropdown(false);
    } else if (value === "training") {
      setActiveHeader("Book a FREE Training Session");
      setShowDropdown(false);
    } else if (value === "grooming") {
      setActiveHeader("Book a Grooming Session");
      setShowDropdown(false);
    }
  }

  // ---------------------- declaration of submission handlers ----------------------------------------

  async function boardingSubmissionHandler() {
    const inputPhone = trainingPhoneRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    const address = addressRef.current.value;
    const startDate = startDateRef.current.value;
    const endDate = endDateRef.current.value;


    if (
      inputPhone.toString().length === 10 &&
      address !== "" &&
      startDate !== "" &&
      endDate !== ""
    ) {
      setTrainingLoading(true);
      const data = {
        ContactNo: inputPhone,
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        Address: address,
        StartDate: startDate,
        EndDate: endDate,
        FormType: "Direct Form",
      };

      await axios
        .post("https://api.apispreadsheets.com/data/QoUeTA8rf8BrEjAW/", data)
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Boarding Registration Fired From Mobile Service Page Form`,
      });
      setTrainingLoading(false);
      navigate("/thankPage");
    } else {
      if (inputPhone.toString().length !== 10) {
        setTrainingPhoneValidation(false);
        trainingPhoneRef.current.value = "";
      }
      if (address === "") {
        setAddressValidation(false);
        addressRef.current.value = "";
      }
      if (startDate === "") {
        setStartDateValidation(false);
      }
      if (endDate === "") {
        setEndDateValidation(false);
      }

      return;
    }
  }

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handlePetNameChange = (event) => {
    setPetName(event.target.value);
  };
  async function walkingSubmissionHandler() {
    const inputPhone = walkingPhoneRef.current.value;
    const inputAddress = "";
    const inputDate = "";

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    if (inputPhone.toString().length === 10) {
      setWalkingLoading(true);
      const data = {
        ContactNo: inputPhone,
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        Address: inputAddress,
        TrialDate: inputDate,
        FormType: "Home Page Form",
      };

      await axios
        .post("https://api.apispreadsheets.com/data/QcQTxBvwdD8rfShs/", data)
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Walking Registration Fired From Mobile Home Page Form`,
      });

      setWalkingLoading(false);
      navigate("/thankPage");
    } else {
      if (inputPhone.toString().length !== 10) {
        setWalkingPhoneValidation(false);
        walkingPhoneRef.current.value = "";
      }
      return;
    }
  }

  async function trainingSubmissionHandler() {
    const inputPhone = trainingPhoneRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    let UTM_Source,
      UTM_Medium,
      UTM_Campaign = "";

    //   handleLocationAccess();

    const urlParams = new URLSearchParams(window.location.search);
    UTM_Source = urlParams.get("utm_source");
    UTM_Medium = urlParams.get("utm_medium");
    UTM_Campaign = urlParams.get("utm_campaign");

    let newLocal = address;
    if (inputPhone.toString().length === 10 && petName.trim() !== "" && city !== "") {
      // handleLocationAccess();
      setTrainingLoading(true);
      const data = {
        data: {
          ContactNo: inputPhone,
          DateOfRegistration: dateOfReg,
          TimeOfRegistration: timeOfReg,
          Address: "",
          location: address,
          FormType: "Direct Form",
          UTM_Source: UTM_Source || "",
          UTM_Medium: UTM_Medium || "",
          UTM_Campaign: UTM_Campaign || "",
          TrialDate: "",
          PetName: petName,
          City: city,
        },
      };

      await axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveTrainingData",
          data
        )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
      setTrainingLoading(false);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Training Registration Fired From Mobile Service Page Form`,
      });
      navigate("/thankPage");
    } else {
      if (inputPhone.toString().length !== 10) {
        setTrainingPhoneValidation(false);
        trainingPhoneRef.current.value = "";
      } else if (petName.trim() === "") {
        setNamePetValidation(false);

      } else {
        setCityValidation(false);
      }

      return;
    }
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(
    serviceAmount,
    SelectedService,
    DateOfReg,
    InputAddress,
    inputPhone
  ) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPayOrderId",
      {
        amount: serviceAmount,
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Petmojo",
      description: "Pet Grooming Payment",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        //console.log("Grooming Page Function: ", data);

        const result = await axios.post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPaySuccess",
          data
        );
        //alert(result.data.msg);
        const Data = {
          ...result.data,
          SelectedService,
          DateOfReg,
          InputAddress,
        };

        const actualAmount = serviceAmount / 100;

        const dataToStore = {
          Date: DateOfReg,
          Address: InputAddress,
          Contact_No: inputPhone.toString(),
          Amount_Paid: actualAmount.toString(),
          Selected_Service: SelectedService,
          Order_id: order_id,
        };

        await axios
          .post(
            "https://api.apispreadsheets.com/data/hq3CiEAYcPfNXIJE/",
            dataToStore
          )
          .then((response) => {
            console.log(response);
            console.log("The grooming payment has been completed!");
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in submitting the grooming payment to backend!"
            );
          });

        setPaymentData(Data);
        setFlag(true);
      },
      // prefill: {
      //     name: "Petmojo",
      //     email: "customercare@petmojo.in",
      //     contact: "+91 9009004473",
      // },
      notes: {
        address: "Petmojo",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    //console.log(paymentObject);
    paymentObject.open();
  }
  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    if (flag) navigate("/ordersummary", { state: paymentData });
  }, [paymentData, flag]);

  async function groomingSubmissionHandler() {
    const inputPhone = groomingPhoneRef.current.value;
    const inputAddress = groomingAddressRef.current.value;
    const inputDate = groomingDateRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${date.getMonth() + 1
      }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    const enteredYear = parseInt(inputDate.substring(0, 4));
    const enteredMonth = parseInt(inputDate.substring(5, 7));
    const enteredDay = parseInt(inputDate.substring(8, 10));

    if (
      inputPhone.toString().length === 10 &&
      inputAddress !== "" &&
      inputDate !== "" &&
      enteredYear >= date.getFullYear() &&
      enteredMonth >= date.getMonth() + 1
    ) {
      if (enteredMonth === date.getMonth() + 1) {
        if (enteredDay <= date.getDate()) {
          setGroomingDateValidation(false);
          return;
        }
      }

      setGroomingLoading(true);

      let srv = "";
      if (selectedFirst) {
        srv = "BATH & BRUSH";
      }
      if (selectedNew) {
        srv = "BATH & BRUSH (with tick treatment)";
      }
      if (selectedSecond) {
        srv = "HAIRCUT & STYLING";
      }
      if (selectedThird) {
        srv = "BATH & FULL HAIRCUT";
      }

      var amount = 0;

      if (selectedService === "BATH & BRUSH") {
        //kartik
        amount = 799;
      } else if (selectedService === "BATH & BRUSH (With Tick Treatment)") {
        amount = 1199;
      } else if (selectedService === "HAIRCUT & STYLING") {
        amount = 1249;
      } else if (selectedService === "BATH & FULL HAIRCUT") {
        amount = 1799;
      }

      let UTM_Source, UTM_Medium, UTM_Campaign = "";

      const urlParams = new URLSearchParams(window.location.search);
      UTM_Source = urlParams.get('utm_source');
      UTM_Medium = urlParams.get('utm_medium')
      UTM_Campaign = urlParams.get('utm_campaign')


      const data = {
        ContactNo: inputPhone,
        Services: srv,
        Date: inputDate,
        Address: inputAddress,
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        FormType: "Home Page Form",
        UTM_Source: UTM_Source,
        UTM_Medium: UTM_Medium,
        UTM_Campaign: UTM_Campaign
      };
      data["Amount"] = amount;
      data["paymentStatus"] = 2;
      await axios
        .post("https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveGroomingData", data)
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });

      setGroomingLoading(false);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Grooming Registration Fired From Mobile Home Page Form`,
      });

      displayRazorpay(
        amount,
        selectedService,
        dateOfReg,
        inputAddress,
        inputPhone
      );
    } else {
      if (inputPhone.toString().length !== 10) {
        setGroomingPhoneValidation(false);
        groomingPhoneRef.current.value = "";
      }
      if (inputAddress === "") {
        setGroomingAddressValidation(false);
        groomingAddressRef.current.value = "";
      }
      if (inputDate === "") {
        setGroomingDateValidation(false);
        groomingDateRef.current.value = "";
      }
      if (enteredYear < date.getFullYear()) {
        setGroomingDateValidation(false);
        return;
      }
      if (enteredMonth < date.getMonth() + 1) {
        setGroomingDateValidation(false);
        return;
      }

      return;
    }
  }
  // const setDate=()=>{
  //   var today=new Date();
  //   var ele=document.querySelector(myDate);
  //   ele.value=today.toISOString().substr(0,10);

  // }
  const openCalendar = () => {
    document.getElementById("startDate").style.visibility = "visible";
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // const years = range(1990, getYear(new Date()) + 1, 1);

  const formShiftHandler = () => {
    window.scrollTo(0, 400);
  };
  // -------------------------------- JSX Code --------------------------------------
  const useLocation123 = () => {
    // const { coords } = useGeolocated();
    // console.log(coords);
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        // setLocation({ latitude, longitude });
        console.log("asdf", latitude, longitude)
        setLat(latitude);
        setLon(longitude);
        // const result=getAddress(latitude,longitude);
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;
        fetch(url)
          .then(response => response.json())
          .then(data => {
            const { results } = data;
            if (results && results.length > 0) {
              setAddress(results[9].formatted_address);
              // console.log(address)
            }
          })
          .catch(error => {
            console.log('Error:', error);
          })
      },
      error => {
        console.log(error);
      }
    );
  }
  return (
    <div className="home-page-mobile-view-form-container">
      <div className="home-page-mobile-view-form-selection-container">
        <div
          onClick={() => { setActiveForm("training"); }}
          className={
            activeForm === "training"
              ? "active-home-page-mobile-view-form-option"
              : "home-page-mobile-view-form-option"
          }
        >
          <div>
            <img
              alt="training-icon"
              className="home-page-mobile-view-form-training-image"
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/dog-training+(1).png"
            />
          </div>
          <div className="home-page-mobile-view-form-training-text">
            Dog Training
          </div>
        </div>
        <div
          onClick={() => setActiveForm("walking")}
          className={
            activeForm === "walking"
              ? "active-home-page-mobile-view-form-option"
              : "home-page-mobile-view-form-option"
          }
        >
          <div>
            <img
              alt="training-icon"
              className="home-page-mobile-view-form-training-image"
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/walking-icon.png"
            />
          </div>
          <div className="home-page-mobile-view-form-training-text">
            Daily Dog Walking/Exercise
          </div>
        </div>
        <div
          onClick={() => setActiveForm("grooming")}
          className={
            activeForm === "grooming"
              ? "active-home-page-mobile-view-form-option"
              : "home-page-mobile-view-form-option"
          }
        >
          <div>
            <img
              alt="training-icon"
              className="home-page-mobile-view-form-training-image"
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pet-grooming-icon.png"
            />
          </div>
          <div className="home-page-mobile-view-form-training-text">
            Pet Grooming
          </div>
        </div>
        <div
          onClick={() => { setActiveForm("boarding"); }}
          className={
            activeForm === "boarding"
              ? "active-home-page-mobile-view-form-option"
              : "home-page-mobile-view-form-option"
          }
        >
          <div>
            <img
              alt="training-icon"
              className="home-page-mobile-view-form-training-image"
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/boarding-icon.png"
            />
          </div>
          <div className="home-page-mobile-view-form-training-text">
            Pet Hotel
          </div>
        </div>
      </div>

      {activeForm === "walking" ? (
        <WalkingPageForm />
      ) : null}

      {activeForm === "training" ? (
        <div className="home-page-mobile-view-form-2">
          <div className="home-page-mobile-view-form-2-phone">
            <div className="home-page-mobile-view-form-2-phone-small-container">
              <div className="pet-name-container">Phone Number <img src={phoneIcon} style={{ width: "15px", height: "15px", marginLeft: "2px" }} /></div>
              {trainingPhoneValidation ? null : (
                <div className="home-page-walking-form-mobile-feedback">
                  Please enter a valid number
                </div>
              )}
            </div>
            <input
              ref={trainingPhoneRef}
              onChange={() => setTrainingPhoneValidation(true)}
              onClick={() => formShiftHandler()}
              type="number"
              className="training-page-mobile-view-input"
              placeholder="Enter phone number"
              style={{ fontSize: "17px" }}
            />

            <div
              className="training-page-mobile-view-phone-check-container"
              style={{ marginTop: "0.5rem" }}
            >
              <div className="pet-name-container">Enter Pet Name  <img src={dogsIcon} style={{ width: "15px", height: "15px", marginLeft: "2px" }} /></div>
              {petNameValidation ? null : (
                <div className="home-page-desktop-phone-validation-text">
                  Please enter petname
                </div>
              )}
            </div>
            <input
              //    onChange={() => setTrainingPhoneValidation(true)}
              //    onClick={() => formShiftHandler()}
              type="text"
              className="training-page-mobile-view-input"
              placeholder="Enter Pet Name"
              style={{ fontSize: "17px" }}
              value={petName}
              onChange={(event) => {
                handlePetNameChange(event);
                setNamePetValidation(true);
              }}
              required
            />

            <div
              className="training-page-mobile-view-phone-check-container"
              style={{ marginTop: "0.5rem" }}
            >
              <div className="pet-name-container">Select City <img src={locationIcon} style={{ width: "15px", height: "15px", marginLeft: "2px" }} /> </div>
              {(cityValidation === undefined || cityValidation !== false) ? null : (
                <div className="home-page-desktop-phone-validation-text">
                  Select City
                </div>
              )}
            </div>
            <select
              // onChange={() => setTrainingPhoneValidation(true)}
              // onClick={() => formShiftHandler()}
              className="training-page-mobile-view-input"
              style={{ fontSize: "17px" }}
              value={city}
              onChange={(event) => {
                handleCityChange(event);
                setCityValidation(true);
              }}
              required
            >
              <option value="" disabled selected>
                Select City
              </option>
              <option value="Delhi NCR">Delhi NCR</option>
              <option value="Mumbai Metropolitan City">Mumbai Metropolitan City</option>
            </select>
          </div>
          <div className="" style={{ width: "100%" }}>
            {trainingLoading ? (
              <LoadingSpinner />
            ) : (
              <div
                className="bookingbutton"
                style={{
                  width: "100%",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  marginTop: "10px",
                }}
              >
                <button
                  onClick={() => trainingSubmissionHandler()}
                  className="booksession-form"
                  style={{ width: "100%" }}
                >
                  Book a FREE Session
                </button>
              </div>
            )}
            <div className="home-page-form-walking-training-mobile-section-text">
              <i>We are mission driven & a Family of Pet Lovers</i>
            </div>
          </div>
        </div>
      ) : null}

      {activeForm === "grooming" ? (
        <HomePageGroomingForm />
      ) :
        null}

      {activeForm === "boarding" ? (
        // <div className="home-page-mobile-view-form-2">
        //   <div>
        //     <div className="training-page-mobile-view-form-phone-container-1">
        //       <div className="training-page-mobile-view-phone-check-container">
        //         <div>Phone Number</div>
        //         {trainingPhoneValidation ? null : (
        //           <div className="home-page-walking-form-mobile-feedback">
        //             Please enter a valid number
        //           </div>
        //         )}
        //       </div>
        //       <input
        //         ref={trainingPhoneRef}
        //         onClick={() => formShiftHandler()}
        //         onChange={() => setTrainingPhoneValidation(true)}
        //         type="number"
        //         className="training-page-mobile-view-input"
        //         placeholder="Enter phone number"style={{fontSize:"17px"}}
        //       />
        //     </div>
        //     <div className="training-page-mobile-view-form-phone-container-1">
        //       <div className="training-page-mobile-view-phone-check-container">
        //         <div>Complete Address</div>
        //         {addressValidation ? null : (
        //           <div className="home-page-walking-form-mobile-feedback">
        //             Required
        //           </div>
        //         )}
        //       </div>
        //       <input
        //         ref={addressRef}
        //         onClick= {useLocation123}
        //           // formShiftHan
        //         onChange={(e) => {setAddressValidation(true);
        //         setAddress(e.target.value);
        //         }}
        //         type="text"
        //         className="training-page-mobile-view-input"
        //         placeholder="Enter your complete address"style={{fontSize:"17px"}}
        //         value={address}

        //       />
        //     </div>
        //     <div className="training-page-mobile-view-form-phone-container-1">
        //       <div className="training-page-mobile-view-phone-check-container">
        //         <div>Start Date</div>
        //         {!startDateValidation ? (
        //           <div className="home-page-walking-form-mobile-feedback">
        //             Required
        //           </div>
        //         ) : null}
        //       </div>

        //       {/* <div className='training-page-mobile-view-input' style={{marginBottom:"20px",position:"relative"}} > */}
        //       <div className='walking-section-form-input-date-mobile' style={{marginBottom:"20px",position:"relative",border:"none"}} >
        //       <input className='training-page-mobile-view-input' ref={startDateRef} onChange={() => {setStartDateValidation(true);setStartValue(true)}} type="date" required id='startDate'  value={startValue?startDateRef.current.value:formattedDate} style={{fontSize:"17px"}}/>


        //       </div>

        //       {/* <DatePicker
        //         dateFormat="yyyy-MM-dd"
        //         className="training-page-mobile-view-input"
        //         placeholderText="DD-MM-YYYY"
        //         ref={startDateRef}
        //         onChange={(date) => {
        //           setStartDateValidation(true);
        //           // console.log("asdf",startDateRef.current )
        //           setStartDate(date);
        //         }}
        //         selected={startDate}
        //       /> */}
        //     </div>
        //     <div className="training-page-mobile-view-form-phone-container-1">
        //       <div className="training-page-mobile-view-phone-check-container">
        //         <div>End Date</div>
        //         {!endDateValidation ? (
        //           <div className="home-page-walking-form-mobile-feedback">
        //             Required
        //           </div>
        //         ) : null}
        //       </div>
        //       <div className='walking-section-form-input-date-mobile' style={{marginBottom:"20px",position:"relative",border:"none"}} >

        //       <input ref={endDateRef} onChange={() => {setEndDateValidation(true);setEndValue(true)}} type="date" className='training-page-mobile-view-input' placeholder='DD/MM/YYYY' required value={endValue?endDateRef.current.value:formattedDate} style={{fontSize:"17px"}}/>
        //       </div>
        //       {/* <DatePicker
        //         dateFormat="yyyy-MM-dd"
        //         ref={endDateRef}
        //         onChange={(date) => {
        //           setEndDateValidation(true);
        //           // console.log("asdf",endDateRef.current )

        //           setEndDate(date);
        //         }}
        //         className="training-page-mobile-view-input"
        //         placeholderText="DD-MM-YYYY"
        //         selected={endDate}
        //       /> */}
        //     </div>

        //     {/* <div className='boarding-page-mobile-view-date-container'>
        //                         <div className="dateandtime">
        //                         <div className="select-date">
        //                                     <div style={{ color: "#5E5E5E", fontWeight: "700" }}>
        //                                         Start Date
        //                                         {

        //                                             !startDateValidation ? (
        //                                                 <div  className='home-page-walking-form-mobile-feedback'>
        //                                                     Required
        //                                                 </div>
        //                                             ) : (null)

        //                                         }
        //                                     <input ref={startDateRef} onChange={() => setStartDateValidation(true)} type="date" className='walking-section-form-input-date-mobile' placeholder='DD/MM/YYYY' required />
        //                                     </div>
        //                                 </div>
        //                         </div>

        //                         <div>
        //                         <div>
        //                                     <div className='home-page-mobile-view-form-2-phone-small-container'>
        //                                         <div>End Date</div>
        //                                         {

        //                                             !endDateValidation ? (
        //                                                 <div  className='home-page-walking-form-mobile-feedback'>
        //                                                     Required
        //                                                 </div>
        //                                             ) : (null)

        //                                         }
        //                                     </div>
        //                                     <input ref={endDateRef} onChange={() => setEndDateValidation(true)} type="date" className='walking-section-form-input-date-mobile' placeholder='DD/MM/YYYY' required />
        //                                 </div>
        //                         </div>
        //                     </div> */}

        //     <div className="training-page-mobile-view-form-button">
        //       {trainingLoading ? (
        //         <LoadingSpinner />
        //       ) : (
        //         <div className="bookingbutton">
        //           <button
        //             onClick={() => boardingSubmissionHandler()}
        //             className="booksession-mobile-form"
        //           >
        //             GET QUOTE
        //           </button>
        //         </div>
        //       )}
        //     </div>
        //     <div className="training-page-mobile-view-form-footer">
        //       <i>We are mission driven & a Family of Pet Lovers</i>
        //     </div>
        //   </div>
        // </div>
        <HomePageBoardingForm />
      ) : null}
    </div>
  );
};

export default HomePageForm;
