import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReviewCard from "./ReviewCard";
import ReviewVideos from "./ReviewVideos";
import axios from "axios";
import deskBg from '../../images/reviewDesktopBg.png';
import mobileBg from '../../images/reviewMobileBg.png';
import NavigationDesktop from "../../components-desktop/Navigation/NavigationDesktop";
import NavigationBar from "../../components/Navigation/navigationBar/NavigationBar";
import ReviewFooter from "./ReviewFooter";
import ReviewSection from "./ReviewSection";
import { Helmet } from "react-helmet-async";

const baseUrl = "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api"

const reviewsSectionData = [
  {
    heading: "WhatsApp Feedback",
    description: "50,000+ monthly happy pet parents share their experiences with us.",
    reviews: [
      {
        data: "Shivam, he was chewing aggressively, I said no while showing newspaper, he dropped it Well done teacher",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Whatsapp/Frame+1686560690.png"
      },
      {
        data: "Good work by avinash",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Whatsapp/Frame+1686560691.png"
      },
      {
        data: "He was teaching me how to train the dog to pee and poop, But yes I liked the Session",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Whatsapp/Frame+1686560692.png"
      },
    ]
  },
  {
    heading: "Facebook Feedback",
    description: "47,100+ Followers are connected with us on social media to share their experiences with us.",
    reviews: [
      {
        data: "I had a wonderful experience with Pet Mojo`s dog walking service. Monu, the walker, is punctual and caring. My dog Loves his daily walks with Monu and comes back happy and tired.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Facebook/Frame+1686560693.png"
      },
      {
        data: "Monu at Pet Mojo provides excellent training services. My dog’s behavior has improved significantly. and The grooming services are thorough, and my pet Looks great after each session.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Facebook/Frame+1686560694.png"
      },
      {
        data: "I am extremely satisfied with Pet Mojo. The pet spa treatments are amazing, my dog loves them. The GPS tracking feature is very convenient, ensuring I can always keep track of him.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Facebook/Frame+1686560695.png"
      },
      {
        data: "ankush at Pet Mojo is a great trainer who worked wonder with My dog His expertise in training made a big difference Grooming service are exceptional, and my dog always looks amazing after each visit.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Facebook/Frame+1686560696.png"
      },
      {
        data: "Pet Mojo’s extra services, such as a Luxurious pet spa and convenient GPS tracking ensure that pets are well pampered and secure. Their pet hotel facilities are clean and comfortable. ",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Facebook/Frame+1686560697.png"
      },
      {
        data: "Chandu ar Pet Mojo was an excellent trainer for my dog His patience and professionalism made a huge difference in my pet’s behavior The grooming services were also fantastic.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Facebook/Frame+1686560698.png"
      },
      {
        data: "Pet Mojo did a wonderful job with my cat’s grooming The staff is friendly, and my cat Looked so clean and cute afterward They also offer pet haircut styling which was done perfectly.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Facebook/Frame+1686560699.png"
      },
      {
        data: "Sonu from pet Mojo is an amazing trainer who worked wonders with my dog His calm and understanding approach really helped my dog learn quickly Grooming was handled perfectly.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Facebook/Frame+1686560700.png"
      },
    ]
  },
  {
    heading: "Play store reviews",
    description: "4.8+ Ratings with clients for getting satisfaction to track every session within the application.",
    reviews: [
      {
        data: "I love the cat grooming services at Pet Mojo. My cat always comes back well-groomed and happy. The training sessions are effective, helping my cat become more disciplined. The haircut styling is a wonderful addition, giving my cat a fresh and stylish look. Pet Mojo’s attention to detail and care for pets is exceptional.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Playstore/Frame+1686560704.png"
      },
      {
        data: "I am very satisfied with Pet Mojo’s cat grooming service. My cat always comes back looking well-groomed  and happy. The training has also helped improve her behaviour, making her more obedient. The pet haircut styling service is a great addition, leaving my cat looking stylish. Pet Mojo provides excellent care for cats.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Playstore/Frame+1686560705.png"
      },
      {
        data: "Pet Mojo’s pet training, grooming, and walking services are top-notch. My dog has become more obedient with their training, and the grooming always leaves him looking his best. The walking service is consistent and dependable.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Playstore/Frame+1686560706.png"
      },
      {
        data: "Trainer Ankush at Pet Mojo is exceptional. He handled my dog with such care and expertise during the training sessions Ankush’s methods are effective and have significantly improved my dog’s behaviour. The grooming service is professional, leaving my pet looking fantastic.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Playstore/Frame+1686560707.png"
      },
      {
        data: "Pet Mojo’s pet spa is a relaxing treat for pets. My dog loves the pampering. Their GPS tracking is a lifesaver for peace of mind. The pet hotel facilities are top-notch, ensuring pets feel at home.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Playstore/Frame+1686560708.png"
      },
      {
        data: "Pet Mojo’s pet spa is a relaxing treat for pets. My dog loves the pampering. Their GPS tracking is a lifesaver for peace of mind. The pet hotel facilities are top-notch, ensuring pets feel at home.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Playstore/Frame+1686560709.png"
      },
      {
        data: "I am very happy with Pet Mojo’s cat grooming service . My cat always comes back Looking well-groomed and content. The training session have also been beneficial , improving her behavior.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Playstore/Frame+1686560710.png"
      },
    ]
  },
  {
    heading: "App Store Reviews",
    description: "4.7+ Ratings with clients for getting satisfaction to track every session within the application ",
    reviews: [
      {
        data: "Pet Mojo offers amazing pet spa treatments that my dog loves. The GPS tracking service provides peace of mind during walks. The pet hotel facilities are excellent, offering a cozy and secure stay for my dog when I am away. Highly recommend Pet Mojo for their comprehensive and caring pet services.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/App+store/Frame+1686560712.png"
      },
      {
        data: "Pet Mojo’s pet spa service are amazing. My cat always comes back relaxed and happy. The GPS tracking during walks gives me peace of mind. Their pet hotel facilities are top-notch, providing a safe and comfortable Stay for my cat when I travel. Highly recommend Pet Mojo for their excellent and reliable services.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/App+store/Frame+1686560713.png"
      },
      {
        data: "Chandu is an excellent trainer at Pet Mojo. His calm approach made a big difference in my dog’s behaviour. The grooming service is professional, and their walking service is always on time. Highly recommend for all pet needs.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/App+store/Frame+1686560714.png"
      },
      {
        data: "As we have hired a trainer from pet mojo, his name is Sunny, he is really a good trainer I have ever seen. I would like to share my experience with him. He treats my dog as his own dog, really superb trainer.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/App+store/Frame+1686560715.png"
      },
      {
        data: "Sonu is a skilled trainer who really understands pets. My dog responded so well th his methods.The grooming service at Pet Mojo is always top-notch and their walking service is a great addition. Very satisfied with their services.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/App+store/Frame+1686560716.png"
      },
      {
        data: "Enjoy peace of mind with Pet Mojo’s top-tier pet spa services, designed to keep your furry friend relaxed and happy. Their GPS tracking ensures you can monitor your pet’s whereabouts at all times. The pet hotel offers a home-like atmosphere, so your pet can relax comfortably while you’re away. Together, these services are perfect for my pet.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/App+store/Frame+1686560717.png"
      },
      {
        data: "Pawan’s pet training at Pet Mojo is exceptional, providing clear and effective instruction that benefits both pets and owners. His training sessions are enjoyable and productive. The grooming services are thorough, ensuring pets are clean and well-maintained. Their walking services are tailored to meet each pet’s needs with GPS Tracking.",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/App+store/Frame+1686560718.png"
      },
      {
        data: "Pet Mojo’s grooming services for cats are top-of-the-line, providing both through care and gentle handling. Their pet haircut styling is tailored to your cat’s personality, giving them a fashionable edge. With these services, your cat receives the best in grooming and styling, making every visit a positive experience...",
        url: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/App+store/Frame+1686560719.png"
      },
    ]
  },
]
function Review({ changeView }) {

  const [allReviews, setAllReviews] = useState([]);

  const navigate = useNavigate();

  const getAllReviews = async () => {
    try {
      const res = await axios.post(`${baseUrl}/website/getReviews`);
      setAllReviews(res.data.allReviews);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllReviews();
  }, []);

  return (
    <div className="w-screen h-screen relative overflow-y-auto" style={{ backgroundImage: `url(${changeView ? deskBg : mobileBg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
      <Helmet>
        <title>
        Customers Reviews | Petmojo 
        </title>
        <meta
          name="description"
          content="Read real reviews from happy pet parents about Petmojo's grooming, training, and pet care services. Discover why we're India's trusted pet care company, visit now."
        />
      </Helmet>
      {changeView ? (
        <NavigationDesktop
          submissionType={"none"}
          submissionText={"Download Our App"} />
      ) : (
        <NavigationBar btn={"training"} homePage={true} page={"white"} />
      )}
      <div className="mx-auto max-w-7xl z-1 mb-10">
        <div className="w-full flex flex-col px-5 md:px-1 py-5 gap-16">
          <div className="text-center flex flex-col gap-8">
            <h1 className="mx-auto text-white text-[26px] md:text-5xl font-semibold">Our <span className="text-[#85EA62]">Happy</span> Customers</h1>
            <ReviewVideos changeView={changeView} />
          </div>
          <div className="w-full text-white flex flex-col gap-10 lg:gap-20 lg:px-40 lg:flex-row lg:items-center lg:justify-around">
            <div className=" w-full flex flex-col gap-1">
              <div className="bg-[#6A6967] w-full py-2 px-10 rounded-lg items-center justify-center flex flex-col">
                <div>Call us on</div>
                <div>888 999 18 00</div>
              </div>
              <div className="text-center mt-4">From 9:00am to 7:00pm</div>
              <div className="text-center">Monday to Saturday</div>
            </div>
            <div className="w-full flex flex-col gap-1">
              <div className="bg-[#6A6967] w-full py-2 px-10 rounded-lg items-center justify-center flex flex-col">
                <div>Email us on</div>
                <div>customercare@petmojo.in</div>
              </div>
              <div className="text-center mt-4">We will revert back in</div>
              <div className="text-center">1-2 working days</div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black">
        <div className="mx-auto max-w-7xl z-1 py-20 flex flex-col lg:gap-[120px] gap-[60px]">
          {reviewsSectionData.map((data, index) => <ReviewSection key={index} type={index % 2} data={data} changeView={changeView} />)}
        </div>
      </div>
      <div className="bg-white ">
        <ReviewFooter changeView={changeView} />
      </div>
    </div>
  );
}

export default Review;
