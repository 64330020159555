const CatTrainingPackagesData = [
    {
        packageName: "KITTEN TRAINING",
        noOfSessions: "12",
        duration: "1 Months",
        benefits: [
            "Litter Box/susu potty training",
            "Social Etiquette",
            "Biting Issues ",
            "2 commands - sit stay"
        ],
        moreBenefits: [
        ],
        originalPrice: "10,400",
        discountedPrice: "8,900",
        linearGradient: "bg-gradient-to-b from-[#7E0A90] to-[#CE4C95]",
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Intermediate_Training_Cat-removebg-preview.png"
    },
    {
        packageName: "BASIC TRAINING",
        noOfSessions: "24",
        duration: "2 Months",
        benefits: [
            "Litter Box/susu potty training",
            "Social Etiquette",
            "Biting Issues ",
            "5 commands - Sit, Stay, Lay Down, Come, Go"
        ],
        moreBenefits: [
        ],
        originalPrice: "20,540",
        discountedPrice: "15,800",
        linearGradient: "bg-gradient-to-b from-[#FE8788] to-[#EA2179]",
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Cat_Basic_Training-removebg-preview.png"
    },
    {
        packageName: "INTERMEDIATE TRAINING",
        noOfSessions: "36",
        duration: "3 Months",
        benefits: [
            "Litter Box/susu potty training",
            "Social Etiquette",
            "Biting Issues ",
            "Touch Acceptance (To be friendly)",
            "Basic House Rules",
            "7 Commands - Sit, Stay, Lay down, Come, Go, Hi-Five, Handshake",
            "3 no commands - No, Don't jump, Don't eat"
        ],
        moreBenefits: [
        ],
        originalPrice: "28,340",
        discountedPrice: "21,800",
        linearGradient: "bg-gradient-to-b from-[#7E0A90] to-[#CE4C95]",
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Intermediate_Training_Cat-removebg-preview.png"

    },
    {
        packageName: "SMART TRAINING",
        noOfSessions: "48",
        duration: "4 Months",
        benefits: [
            "Jump into my arms ",
            "Smart Commands - Go to bed, fetch & drop, Spin",
            "Hula Hoop Jumping",
            "Social Etiquette",
            "Susu Potty Training (in Litter Box), Biting Issue"
        ],
        moreBenefits: [
            "7 Commands - Sit stay lay down come go hi5 Handshake",
            "3 no commands - no, don't jump, don't eat"
        ],
        originalPrice: "36,340",
        discountedPrice: "28,800",
        linearGradient: "bg-gradient-to-b from-[#FE8788] to-[#EA2179]",
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Cat_Basic_Training-removebg-preview.png"
    },
    {
        packageName: "ADVANCED TRAINING",
        noOfSessions: "72",
        duration: "6 Months",
        benefits: [
            "Jump into my arms",
            "Trick Sequence",
            "Discrimination Tasks - Colours, Objects",
            "Crate Adaptation",
            "Smart Commands - Go to bed, fetch & drop, Spin",
            "Hula Hoop Jumping"
        ],
        moreBenefits: [
            "Social Etiquette",
            "Susu Potty Training (in Litter Box)",
            "Biting Issues",
            "Touch Acceptance (To be friendly)",
            "Basic House Rules",
            "7 Commands - Sit, Stay, Lay down, Come, Go, Hi-Five, Handshake",
            "3 no commands - No, Don't jump, Don't eat"
        ],
        originalPrice: "57,720",
        discountedPrice: "44,400",
        linearGradient: "bg-gradient-to-b from-[#EFCB26] to-[#EFCB26]",
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Cat_Advanced_Training-removebg-preview.png"
    }
]




const DogTrainingPackagesData = [
    {
        packageName: "PUPPY TRAINING",
        noOfSessions: "12 Sessions",
        duration: "1 Months",
        benefits: [
            "Potty / Pee Training",
            "Basic Social Etiquette",
            "Puppy Biting Correction",
            "Instruction - Sit, Stay, Shake Hand",
            "Walking - Come, Go",
            "FREE Socialisation Chart",
            "Free Diet Chart"
        ],
        moreBenefits: [
        ],
        originalPrice: "10,400",
        discountedPrice: "8,900",
        linearGradient: "bg-gradient-to-b from-[#FE8788] to-[#EA2179]",
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/train1_bj2t2z.png"
    },
    {
        packageName: "BASIC TRAINING",
        noOfSessions: "24 Sessions",
        duration: "2 Months",
        benefits: [
            "7 Basic Commands - Sit, Down, Stay, Hi5, Handshake, Come, Go",
            "5 No Commands - No, Don't Jump, Don't bark, Don't Pull, Don't Eat",
            "Advanced Potty / Pee Training ",
            "Biting/Mouthing Correction",
            "FREE Socialisation Chart",
            "Free Diet Chart"
        ],
        moreBenefits: [
        ],
        originalPrice: "20,540",
        discountedPrice: "15,800",
        linearGradient: "bg-gradient-to-b from-[#7E0A90] to-[#CE4C95]",
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/train2_ewcp5q.png"
    },
    {
        packageName: "INTERMEDIATE TRAINING",
        noOfSessions: "36 Sessions",
        duration: "3 Months",
        benefits: [
            "Leash Walking - Heel Walk, Turn, Slow Walk, Fast Walk",
            "Lower Acrobat Module - Crawl, Rollover, Lie Down,  Sleep (Play dead)",
            "Advanced Potty / Pee Training",
            "Basic Social Etiquette - Don't Jump, Don't Pull,  Boundaries in House",
            "Biting/Mouthing Correction",
        ],
        moreBenefits: [
            "7 Commands - Sit, Stay, Lay down, Come, Go, Hi-Five, Handshake",
            "5 No Commands - No, Don't Jump, Don't bark, Don't  Pull, Don't Eat",
            "FREE Socialization Chart (Provided on app)",
            "FREE Diet Chart Provided"
        ],
        originalPrice: "28,340",
        discountedPrice: "21,800",
        linearGradient: "bg-gradient-to-b from-[#00C4B0] to-[#008084]",
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/train34_njeqpx.png"

    },
    {
        packageName: "SMART TRAINING",
        noOfSessions: "48 Sessions",
        duration: "4 Months",
        benefits: [
            "Smart Commands - Go To Bed, Fetch Newspaper",
            "Counting 1 to 5",
            "Advanced Play - Hula-Hoop Jumps, Fetch & drop, Spin",
            "Advanced Greetings - Namaste, Shake Hand, High-five",
            "Lower Acrobat Module - Crawl, Rollover, Lie Down, Sleep  (play dead)",
        ],
        moreBenefits: [
            "Advanced Potty / Pee Training",
            "Basic Social Etiquette's - Don't Jump, Don't Pull,  Boundaries in House",
            "Biting/Mouthing Correction",
            "7 Basic Commands - Sit, Down, Stay, Hi5, Handshake,  Come, Go",
            "5 No Commands - No, Don't Jump, Don't bark, Don't Pull,  Don't Eat",
            "Leash Walking - Heel Walk, Turn, Slow Walk, Fast Walk ",
            "Instruction - Sit, Advanced Stay, Down, Get Up (Commands  follow in distractions as well)",
            "FREE Socialization Chart (Provided on app)",
            "FREE Diet Chart Provided",
        ],
        originalPrice: "36,340",
        discountedPrice: "28,800",
        linearGradient: "bg-gradient-to-b from-[#F69C42] to-[#E72472]",
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/train56_cx9rau.png"
    },
    {
        packageName: "ADVANCED TRAINING",
        noOfSessions: "72 Sessions",
        duration: "6 Months",
        benefits: [
            "Advanced Off Leash walking - Without leash walking ",
            "Advanced Obedience Training",
            "Smart Commands - Go To Bed, Fetch Newspaper",
            "Advanced Play - Hula-Hoop Jumps, Fetch & drop, Spin ",
            "Advanced Greetings - Namaste, Shake Hand, High-five",
        ],
        moreBenefits: [
            "Counting 1 to 5",
            "Advanced Off Leash walking - Without leash walking",
            "Lower Acrobat Module - Crawl, Rollover, Lie Down, Sleep  (play dead)",
            "Advanced Potty / Pee Training",
            "Basic Social Etiquette's - Don't Jump, Don't Pull,  Boundaries in House",
            "Biting/Mouthing Correction",
            "7 Basic Commands - Sit, Down, Stay, Hi5, Handshake,  Come, Go",
            "5 No Commands - No, Don't Jump, Don't bark, Don't Pull,  Don't Eat",
            "Leash Walking - Heel Walk, Turn, Slow Walk, Fast Walk",
            "Certificate Provided",
            "FREE Socialization Chart (Provided on app)",
            "FREE Diet Chart Provided",
        ],
        originalPrice: "57,720",
        discountedPrice: "44,400",
        linearGradient: "bg-gradient-to-b from-[#F69C42] to-[#E72472]",
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/train56_cx9rau.png"
    },
    {
        packageName: "ALL IN ONE TRAINING",
        noOfSessions: "144 Sessions",
        duration: "12 Months",
        benefits: [
            "Advanced Agility Training  - Hurdle Jump, Cone Cross Walk, Between  the Legs Walk (weave)",
            "Theatrics - Play dead act, statue/freeze Sniff & Search",
            "Advanced Obedience Training",
            "Protection & Guard Module - Guarding Position, Hold & Bark  in Guarding Position",
        ],
        moreBenefits: [
            "Protection & Guard Module - Alert Bark on doorbell, Alert  Bark on Stranger",
            "Smart Commands - Go To Bed, Fetch Newspaper",
            "Advanced Play - Hula-Hoop Jumps, Fetch & drop, Spin ",
            "Advanced Greetings - Namaste, Shake Hand, High-five",
            "Counting 1 to 5",
            "Advanced Off Leash walking - Without leash walking",
            "Lower Acrobat Module - Crawl, Rollover, Lie Down, Sleep  (play dead)",
            "Advanced Potty / Pee Training",
            "Basic Social Etiquette's - Don't Jump, Don't Pull,  Boundaries in House",
            "Biting/Mouthing Correction",
            "7 Basic Commands - Sit, Down, Stay, Hi5, Handshake,  Come, Go",
            "5 No Commands - No, Don't Jump, Don't bark, Don't Pull,  Don't Eat",
            "Leash Walking - Heel Walk, Turn, Slow Walk, Fast Walk",
            "Certificate Provided",
            "FREE Socialization Chart (Provided on app)",
            "FREE Diet Chart Provided",
        ],
        originalPrice: "96,600",
        discountedPrice: "1,29,456",
        linearGradient: "bg-gradient-to-b from-[#4BED48] to-[#1F951D]",
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/ShowDogTraining.png"
    },
    {
        packageName: "CANINE BEHAVIOUR THERAPY",
        noOfSessions: "48 Days",
        duration: "4 Months",
        benefits: [
            "Excessive Barking",
            "Biting",
            "Growling",
            "Lunging on other people",
            "Fear Anxiety",
            "Hyperactivity",
            "Separation Anxiety",
            "Aggressive behaviour",
        ],
        moreBenefits: [
            "Excessive leash pulling",
            "Excessive chewing of household items",
            "FREE Socialization Chart (Provided on app)",
            "FREE Diet Chart Provided",
        ],
        originalPrice: "52,520",
        discountedPrice: "32,200",
        linearGradient: "bg-gradient-to-b from-[#C38C63] to-[#EFCB26]",
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_utmpdt.png"
    },
    {
        packageName: "PUPPY TRAINING",
        noOfSessions: "30 Days",
        duration: "1 Months",
        benefits: [
            "Potty / Pee Training",
            "Basic Social Etiquette",
            "Puppy Biting Correction",
            "Instruction - Sit, Stay, Shake Hand",
            "Walking - Come, Go",
            "FREE Socialisation Chart",
            "Free Diet Chart"
        ],
        moreBenefits: [
        ],
        originalPrice: "22,700",
        discountedPrice: "18,100",
        linearGradient: "bg-gradient-to-b from-[#8DDE71] to-[#004440]",
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/canine-behaviour-icon.png",
        stayIncluded: true,
    },
    {
        packageName: "BASIC TRAINING",
        noOfSessions: "60 Days",
        duration: "2 Months",
        benefits: [
            "7 Basic Commands - Sit, Down, Stay, Hi5, Handshake, Come, Go",
            "5 No Commands - No, Don't Jump, Don't bark, Don't Pull, Don't Eat",
            "Advanced Potty / Pee Training ",
            "Biting/Mouthing Correction",
            "FREE Socialisation Chart",
            "Free Diet Chart"
        ],
        moreBenefits: [
        ],
        originalPrice: "38,400",
        discountedPrice: "32,200",
        linearGradient: "bg-gradient-to-b from-[#8DDE71] to-[#004440]",
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/canine-behaviour-icon.png",
        stayIncluded: true,
    },
    {
        packageName: "INTERMEDIATE TRAINING",
        noOfSessions: "90 Days",
        duration: "3 Months",
        benefits: [
            "Leash Walking - Heel Walk, Turn, Slow Walk, Fast Walk",
            "Lower Acrobat Module - Crawl, Rollover, Lie Down,  Sleep (Play dead)",
            "Advanced Potty / Pee Training",
            "Basic Social Etiquette - Don't Jump, Don't Pull,  Boundaries in House",
            "Biting/Mouthing Correction",
        ],
        moreBenefits: [
            "7 Commands - Sit, Stay, Lay down, Come, Go, Hi-Five, Handshake",
            "5 No Commands - No, Don't Jump, Don't bark, Don't  Pull, Don't Eat",
            "FREE Socialization Chart (Provided on app)",
            "FREE Diet Chart Provided"
        ],
        originalPrice: "54,300",
        discountedPrice: "45,500",
        linearGradient: "bg-gradient-to-b from-[#8DDE71] to-[#004440]",
        src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/canine-behaviour-icon.png",
        stayIncluded: true,
    },
]

module.exports = { CatTrainingPackagesData, DogTrainingPackagesData };